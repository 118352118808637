var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";
import { BREAKPOINTS, Col, Container, Row, onBreakpoint, rem, } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { RenderProperty, ShowPropertyOnEdit } from "@/src/views/RenderProperty";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
import { RichTextWithTypographyRenderer } from "./RichTextBlock/RichTextBlock";
import { brandColors } from "@basics/brand-colors";
var useStyles = createUseStyles({
    element: __assign(__assign({ marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
    })),
    headline: __assign(__assign({}, typography.h2), { textAlign: "center" }),
    description: customTypography(__assign(__assign({}, typography.textDefault), { textAlign: "center" }), { marginBottom: rem(spacings.s) }, { marginBottom: rem(spacings.sam) }, { marginBottom: rem(spacings.m) }),
    item: __assign(__assign(__assign({ marginBottom: rem(spacings.xs) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.s),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.sam),
    })), { alignItems: "center" }),
    singleRow: {
        "& [class*='col']:last-of-type $item": {
            marginBottom: 0,
        },
    },
    richText: {
        "& :last-child": {
            marginBottom: 0,
        },
    },
    iconColumn: {
        alignSelf: "baseline",
        textAlign: "center",
    },
    icon: __assign({}, FAIconStyles({
        color: brandColors.grey,
        height: "100%",
        width: "100%",
    })),
    iconSmall: {
        maxWidth: 80,
        maxHeight: 80,
    },
    iconBig: {
        maxWidth: 120,
        maxHeight: 120,
    },
    iconTwoColumnSmall: __assign({ maxWidth: 56, maxHeight: 56 }, onBreakpoint("sm", {
        maxWidth: 72,
        maxHeight: 72,
    })),
    iconTwoColumnBig: __assign({ maxWidth: 96, maxHeight: 96 }, onBreakpoint("sm", {
        maxWidth: 112,
        maxHeight: 112,
    })),
    textColumn: {
        alignSelf: "center",
    },
    grayBg: __assign(__assign({ backgroundColor: function (isGrayBg) { return isGrayBg && brandColors.ultraLightGrey; }, padding: "".concat(spacings.m, " ").concat(spacings.sam, " ").concat(spacings.m) }, onBreakpoint("sm", {
        padding: "".concat(spacings.l, " ").concat(spacings.m, " ").concat(spacings.l),
    })), onBreakpoint("md", {
        padding: "".concat(spacings.xxl, " ").concat(spacings.l, " ").concat(spacings.xxl),
    })),
    bgRow: {
        maxWidth: BREAKPOINTS["xl"],
        margin: "0 auto",
    },
});
export function IconWithTextListBlock(props) {
    var _a, _b, _c, _d;
    var isSingleColumn = props.data.layout.value === "single-column";
    var isIconSmall = props.data.iconSize.value === "icon-small";
    var isGrayBg = ((_a = props.data.backgroundColor) === null || _a === void 0 ? void 0 : _a.value) === "gray";
    var styles = useStyles(isGrayBg);
    return (React.createElement(Container, { dataTestid: "IconWithTextListBlock", isFluid: isGrayBg, className: cn(styles.element, isGrayBg ? styles.grayBg : undefined) },
        React.createElement(Row, { className: isGrayBg ? styles.bgRow : undefined },
            ShowPropertyOnEdit(props.data.headline) && (React.createElement(Col, null,
                React.createElement(SemanticHeader, { className: styles.headline, headerSize: (_b = props.data.headerSize) === null || _b === void 0 ? void 0 : _b.value },
                    React.createElement(RenderProperty, { value: props.data.headline })))),
            ShowPropertyOnEdit(props.data.description) && (React.createElement(Col, { sm: 10, smStart: 1, md: 6, mdStart: 3 },
                React.createElement("div", { className: styles.description },
                    React.createElement(RenderProperty, { value: props.data.description }))))),
        React.createElement(Row, { className: cn(isGrayBg ? styles.bgRow : undefined, isSingleColumn && styles.singleRow) }, isSingleColumn ? (React.createElement(React.Fragment, null, (_c = props.data.items.value) === null || _c === void 0 ? void 0 : _c.map(function (item, idx) { return (React.createElement(SingleColumnLayoutItem, { key: idx, item: item, isIconSmall: isIconSmall })); }))) : (React.createElement(Col, { md: 10, mdStart: 1, lg: 8, lgStart: 2 },
            React.createElement(Row, null, (_d = props.data.items.value) === null || _d === void 0 ? void 0 : _d.map(function (item, idx) { return (React.createElement(TwoColumnLayoutItem, { key: idx, item: item, isIconSmall: isIconSmall })); })))))));
}
function SingleColumnLayoutItem(props) {
    var styles = useStyles();
    return (React.createElement(Col, null,
        React.createElement(Row, { className: styles.item },
            React.createElement(Col, { xs: 1, sm: props.isIconSmall ? 1 : 2, smStart: 1, mdStart: 2, className: styles.iconColumn },
                React.createElement("div", null,
                    React.createElement(FAIcon, { icon: (props.item.image || ""), className: cn(styles.icon, props.isIconSmall ? styles.iconSmall : styles.iconBig) }))),
            React.createElement(Col, { xs: 3, sm: props.isIconSmall ? 9 : 8, smStart: props.isIconSmall ? 2 : 3, md: props.isIconSmall ? 7 : 6, mdStart: props.isIconSmall ? 3 : 4, className: styles.textColumn },
                React.createElement(RichTextWithTypographyRenderer, { value: props.item.richText || "", className: styles.richText })))));
}
function TwoColumnLayoutItem(props) {
    var styles = useStyles();
    return (React.createElement(Col, { sm: 6, md: 6 },
        React.createElement(Row, { className: styles.item },
            React.createElement(Col, { xs: 1, sm: props.isIconSmall ? 3 : 4, className: styles.iconColumn },
                React.createElement("div", null,
                    React.createElement(FAIcon, { icon: (props.item.image || ""), className: cn(styles.icon, props.isIconSmall
                            ? styles.iconTwoColumnSmall
                            : styles.iconTwoColumnBig) }))),
            React.createElement(Col, { xs: 3, sm: props.isIconSmall ? 9 : 7, smStart: props.isIconSmall ? 3 : 4, className: styles.textColumn },
                React.createElement(RichTextWithTypographyRenderer, { value: props.item.richText || "", className: styles.richText })))));
}
