export var brandColors = {
    white: "#ffffff",
    black: "#000000",
    cyan10: "#e5f6f6",
    cyan30: "#99dadc",
    lightBlue: "#00a3a8",
    textLink: "#008080",
    blue: "#003960",
    ultraLightGrey: "#F9F9FA",
    lightGrey: "#EEEEEF",
    mediumGrey: "#C6C7C8",
    grey: "#6C6F70",
    darkGrey: "#282828",
    lightRed: "#FCE5E7",
    red: "#E60018",
    darkRed: "#8d1429",
    lightGreen: "#CCE1D4",
    universalGreen10: "#EFF6E7",
    universalGreen20: "#E0EDD1",
    universalGreen40: "#C1DBA3",
    universalGreen: "#65A518",
    universalGreenHover: "#558817",
    green: "#006729",
    darkGreen: "#001A0A",
    lightOchre: "#FFF4E5",
    mediumOchre: "#FFC266",
    yellowOchre70: "#FFB84D",
    yellowOchre: "#FF9900",
    ochre: "#db6301",
    webGreen50: "#6c9c7e", // Needs to be checked
};
export var textColors = {
    white: "#ffffff",
    black: "#000000",
};
