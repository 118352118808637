import "intersection-observer";
import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import "./wdyr";
ReactDOM.render(React.createElement(React.StrictMode, null,
    React.createElement(App, null)), document.getElementById("root"));
serviceWorker.unregister();
