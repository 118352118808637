var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { animations } from "@basics/animations";
import { fonts } from "@basics/fonts";
import { Col, Container, onBreakpoint, rem, Row } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { RenderProperty } from "@/src/views/RenderProperty";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { LanguagePicker } from "@components/LanguagePicker/LanguagePicker";
import { Link } from "./Link";
import { brandColors } from "@basics/brand-colors";
var useStyles = createUseStyles({
    container: __assign(__assign({ backgroundColor: brandColors.lightGrey }, fonts.medium), onBreakpoint("sm", {
        paddingTop: 70,
    })),
    desktopVector: {
        display: "none",
    },
    upperContainer: __assign(__assign({ marginBottom: rem(spacings.l) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.xxl),
    })), onBreakpoint("md", {
        marginBottom: 0,
    })),
    bottomContainerLinks: __assign(__assign({ paddingTop: rem(spacings.l), paddingBottom: rem(spacings.l) }, onBreakpoint("sm", {
        paddingBottom: rem(spacings.xl),
    })), onBreakpoint("md", {
        paddingTop: rem(spacings.xl),
    })),
    horizontalLine: __assign({ borderBottom: "1px solid ".concat(brandColors.ultraLightGrey) }, onBreakpoint("sm", {
        borderBottom: "none",
    })),
    cards: __assign(__assign({ marginTop: rem(spacings.xxl) }, onBreakpoint("sm", {
        marginTop: rem(spacings.xl.minus(spacings.l)),
    })), onBreakpoint("md", {
        marginTop: 0,
    })),
    card: {
        backgroundColor: brandColors.white,
        marginBottom: rem(spacings.s),
        display: "flex",
        alignItems: "center",
        color: brandColors.grey,
        transition: "color 0.3s ease",
        "&:hover": {
            color: brandColors.darkGrey,
            transition: "color 0.3s ease",
        },
        "&:hover $cardIcon": {
            transform: "scale(1)",
            transition: "transform 0.4s ease",
        },
    },
    cardImageWrapper: {
        width: 64,
        height: 64,
        marginRight: rem(spacings.s),
        overflow: "hidden",
    },
    cardImage: {
        width: "100%",
        height: "100%",
    },
    cardIcon: __assign(__assign({ transform: "scale(0.85)", transition: "transform 0.4s ease" }, FAIconStyles({
        color: brandColors.mediumGrey,
        width: 22,
        height: 22,
    })), { marginLeft: "auto", marginRight: rem(spacings.s), flexShrink: 0 }),
    socialIcons: {
        display: "flex",
        alignItems: "center",
    },
    legalLinks: __assign({ display: "flex", flexDirection: "column", marginTop: rem(spacings.l), fontSize: 14 }, onBreakpoint("sm", {
        flexDirection: "row",
        alignItems: "center",
        marginTop: 0,
    })),
    legalLink: __assign(__assign({ display: "flex", flexDirection: "column" }, onBreakpoint("sm", {
        flexDirection: "row",
        alignItems: "center",
    })), { "& a": __assign(__assign(__assign({ display: "block", marginBottom: rem(spacings.s) }, animations.linkChevronEffect), onBreakpoint("sm", {
            marginBottom: 0,
            marginRight: rem(spacings.sam),
        })), onBreakpoint("md", {
            marginRight: rem(spacings.xxl),
        })) }),
    mobileOnly: __assign({ display: "block" }, onBreakpoint("sm", {
        display: "none",
    })),
    desktopAndTabletOnly: __assign({ display: "none" }, onBreakpoint("sm", {
        display: "block",
    })),
    languagePicker: __assign({ display: "none" }, onBreakpoint("md", {
        display: "flex",
        alignItems: "baseline",
        justifyContent: "flex-end",
        marginTop: rem(spacings.sam.minus(spacings.xl)),
    })),
});
export var Footer = function (props) {
    var _a, _b, _c;
    var styles = useStyles();
    var _d = useState(""), openSection = _d[0], setOpenSection = _d[1];
    return (React.createElement("footer", { className: styles.container, "data-testid": "footer", id: "footer" },
        React.createElement(Container, { className: styles.upperContainer },
            React.createElement(Row, null,
                React.createElement(Col, { sm: 4, md: 3, className: styles.desktopAndTabletOnly },
                    React.createElement(RenderProperty, { value: props.column1 })),
                React.createElement(Col, { sm: 4, smStart: 4, md: 3, mdStart: 3, className: styles.desktopAndTabletOnly },
                    React.createElement(RenderProperty, { value: props.column2 })),
                React.createElement(Col, { sm: 4, smStart: 8, md: 3, mdStart: 6, className: styles.desktopAndTabletOnly },
                    React.createElement(RenderProperty, { value: props.column3 })),
                React.createElement(Col, { className: styles.mobileOnly },
                    React.createElement("div", { className: styles.horizontalLine },
                        React.createElement(RenderProperty, { value: props.column1, context: { setOpenSection: setOpenSection, openSection: openSection, uncollapsed: true } })),
                    React.createElement(RenderProperty, { value: props.column2, context: { setOpenSection: setOpenSection, openSection: openSection } }),
                    React.createElement(RenderProperty, { value: props.column3, context: { setOpenSection: setOpenSection, openSection: openSection } })),
                React.createElement(Col, { sm: 12, md: 3, mdStart: 9 },
                    React.createElement(Container, { isFluid: true, className: styles.cards },
                        React.createElement(Row, null, (_b = (_a = props.column4) === null || _a === void 0 ? void 0 : _a.expandedValue) === null || _b === void 0 ? void 0 : _b.map(function (item, idx) { return (React.createElement(FooterCard, __assign({}, item, { key: idx }))); })))))),
        React.createElement(Container, null,
            React.createElement(Row, { className: styles.bottomContainerLinks },
                React.createElement(Col, { sm: 4, md: 3 },
                    React.createElement(RenderProperty, { value: props.socialMedia, className: styles.socialIcons })),
                React.createElement(Col, { sm: 8, smStart: 4, md: 7, mdStart: 3, className: styles.legalLinks },
                    React.createElement(RenderProperty, { value: props.legalLinks, className: styles.legalLink })),
                React.createElement(Col, { md: 2, mdStart: 10, className: styles.languagePicker }, ((_c = props.footerLanguagePicker) === null || _c === void 0 ? void 0 : _c.value) && React.createElement(LanguagePicker, null))))));
};
var FooterCard = function (props) {
    var _a, _b;
    var styles = useStyles();
    var link = (_a = props.link.value) === null || _a === void 0 ? void 0 : _a[0];
    return (React.createElement(Col, { xs: 4, sm: 6, md: 12 },
        React.createElement(Link, { href: (link === null || link === void 0 ? void 0 : link.href) || "", title: link === null || link === void 0 ? void 0 : link.title, target: link === null || link === void 0 ? void 0 : link.target, className: styles.card },
            React.createElement("div", { className: styles.cardImageWrapper },
                React.createElement("img", { className: styles.cardImage, src: ((_b = props.image.value) === null || _b === void 0 ? void 0 : _b.url) || "", title: props.label.value || "", alt: props.label.value || "", loading: "lazy" })),
            props.label.value,
            React.createElement(FAIcon, { icon: "external-link", className: styles.cardIcon }))));
};
