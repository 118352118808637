var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useState } from "react";
import { Col, Container, onBreakpoint, rem, Row, BREAKPOINTS, } from "@basics/layout";
import { createUseStyles } from "react-jss";
import { fonts } from "@basics/fonts";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import cn from "classnames";
import { LanguagePicker } from "@components/LanguagePicker/LanguagePicker";
import { spacings } from "@basics/spacings";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { RenderComponent } from "@/src/views/RenderComponent";
import { brandColors } from "@basics/brand-colors";
var ICON_SMALL_SIZE_PX = 16;
export var NAVI_GROUP_LIST_LEFT_PADDING = "calc(".concat(ICON_SMALL_SIZE_PX, "px + ").concat(spacings.xs, ")");
var useStyles = createUseStyles({
    monsterNavigation: function (_a) {
        var isOpened = _a.isOpened;
        return (__assign(__assign({ backgroundColor: brandColors.white }, fonts.medium), { color: brandColors.darkGreen, position: "absolute", boxShadow: "0 1px 12px 0 rgba(0, 0, 0, 0.09)", width: "100%", height: "100vh", paddingBottom: 0, overflowY: "auto", zIndex: -1, transform: isOpened ? "scale(1,1)" : "scale(1,0)", transformOrigin: "center top", transition: (isOpened ? "transform 0.3s ease" : "transform 0.4s ease") +
                ", border-color 0.5s ease", transitionDelay: isOpened ? "0s" : "0.2s" }));
    },
    monsterNavigationRwd: __assign(__assign({ paddingTop: rem(spacings.s) }, onBreakpoint("sm", {
        paddingTop: "".concat(rem(spacings.sam), " !important"),
        paddingBottom: "".concat(rem(spacings.xxl.times(2)), " !important"),
    })), onBreakpoint("md", {
        paddingTop: "".concat(rem(spacings.m), " !important"),
    })),
    monsterNavigationRwdStartPage: __assign(__assign({ paddingTop: "".concat(rem(spacings.l.times(2)), " !important") }, onBreakpoint("sm", {
        paddingBottom: "".concat(rem(spacings.xl), " !important"),
    })), onBreakpoint("md", {
        paddingTop: "".concat(rem(spacings.xl.add(spacings.xxl)), " !important"),
    })),
    monsterNavigationInside: function (_a) {
        var isOpened = _a.isOpened;
        return ({
            opacity: isOpened ? 1 : 0,
            transition: "opacity 0.3s ease",
            transitionDelay: isOpened ? "0.1s" : "0s",
        });
    },
    naviGroup: __assign(__assign(__assign({ marginBottom: 0 }, onBreakpoint("sm", {
        marginBottom: rem(spacings.sam),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.m),
    })), { "&.active": __assign({ backgroundColor: brandColors.ultraLightGrey, width: "calc(100% + ".concat(spacings.m, ")"), transform: "translateX(-".concat(spacings.s, ")"), padding: "0 ".concat(spacings.s) }, onBreakpoint("sm", {
            backgroundColor: brandColors.white,
        })) }),
    naviGroupButton: {
        width: "100%",
        padding: 0,
        border: "none",
        font: "inherit",
        color: "inherit",
        textAlign: "inherit",
        background: "none",
    },
    naviGroupHeader: __assign(__assign({ display: "flex", alignItems: "center", padding: "".concat(spacings.s, " 0") }, onBreakpoint("sm", {
        marginBottom: rem(spacings.s),
        padding: 0,
    })), { "& img": {
            maxWidth: 24,
            maxHeight: 24,
        }, "& a, & span": __assign({ color: brandColors.darkGrey, fontWeight: "normal", marginLeft: rem(spacings.xs), textDecoration: "none" }, onBreakpoint("sm", {
            fontWeight: "bold",
        })), "& svg": {
            "&:last-child": __assign({ display: "block", marginLeft: "auto" }, onBreakpoint("sm", {
                display: "none",
            })),
        } }),
    naviGroupList: __assign(__assign({ paddingLeft: NAVI_GROUP_LIST_LEFT_PADDING, height: 0, opacity: 0, overflow: "hidden", margin: 0, "&.active": {
            height: "auto",
            opacity: 1,
        } }, onBreakpoint("sm", {
        height: "auto",
        opacity: 1,
        overflow: "visible",
    })), { "& li": __assign(__assign({ listStyle: "none", marginBottom: rem(spacings.sam) }, onBreakpoint("sm", {
            marginBottom: rem(spacings.xs),
        })), { "& a": __assign({ textDecoration: "none", lineHeight: "1.5rem", transition: "color 0.2s ease", "&:hover": {
                    color: brandColors.darkGrey,
                    transition: "0.2s ease",
                } }, onBreakpoint("md", {
                color: brandColors.grey,
            })) }) }),
    leftGroupColumn: __assign({ position: "relative" }, onBreakpoint("md", {
        "&:after": {
            content: "''",
            display: "block",
            width: 2,
            height: "100%",
            position: "absolute",
            // INFO(mkarol): Smooth trasition from 0px at breakpoint start margin to 32px at breakpoint end.
            right: "calc(min((100vw - ".concat(BREAKPOINTS["md"], "px) / 8, 32px))"),
            top: 0,
            backgroundColor: brandColors.lightGrey,
        },
    })),
    lastLeftColumn: __assign({}, onBreakpoint("md", {
        // INFO(mkarol): Smooth trasition from 16px margin at breakpoint start to 32px at breakpoint end.
        marginRight: "calc(".concat(spacings.s, " + min((100vw - ").concat(BREAKPOINTS["md"], "px) / 16, 16px))"),
    })),
    newsletterColumn: __assign(__assign({ marginTop: rem(spacings.xs), paddingTop: rem(spacings.xs), borderTop: "1px solid ".concat(brandColors.lightGrey) }, onBreakpoint("sm", {
        marginTop: rem(spacings.s),
        paddingTop: rem(spacings.l),
    })), onBreakpoint("md", {
        margin: 0,
        padding: 0,
        border: "none",
    })),
    loginSection: __assign({ display: "flex", color: brandColors.darkGrey, backgroundColor: brandColors.ultraLightGrey, marginTop: rem(spacings.sam), justifyContent: "space-between", padding: rem(spacings.s), paddingBottom: 80 }, onBreakpoint("sm", {
        display: "none",
        paddingBottom: rem(spacings.s),
    })),
    icon: FAIconStyles({
        width: 24,
        height: 24,
    }),
    iconSmall: FAIconStyles({
        width: ICON_SMALL_SIZE_PX,
        height: ICON_SMALL_SIZE_PX,
    }),
    iconSmallPlaceholder: {
        width: ICON_SMALL_SIZE_PX,
        height: ICON_SMALL_SIZE_PX,
    },
});
export function MonsterNavigation(props) {
    var _a, _b;
    var getNavigation = useContext(GlobalContentStoreContext).getNavigation;
    var _c = useState(null), activeItem = _c[0], setActiveItem = _c[1];
    var monsterNavigation = getNavigation();
    function chunkArray(arr, chunkCount) {
        var chunks = [];
        while (arr === null || arr === void 0 ? void 0 : arr.length) {
            var chunkSize = Math.ceil(arr.length / chunkCount--);
            var chunk = arr.slice(0, chunkSize);
            chunks.push(chunk);
            arr = arr.slice(chunkSize);
        }
        return chunks;
    }
    var openAccordionFunction = function (column) { return function () {
        return column === activeItem ? setActiveItem(null) : setActiveItem(column);
    }; };
    var styles = useStyles({
        isOpened: props.isOpened,
        isStartPage: props.isStartPage,
    });
    var chunkedMenuLeftColumn = chunkArray(monsterNavigation === null || monsterNavigation === void 0 ? void 0 : monsterNavigation.menuLeftColumn, 3);
    return (React.createElement("nav", { id: "main-menu", "data-testid": "monsternavigation", "aria-expanded": props.isOpened },
        React.createElement(Container, { isFluid: true, className: cn(styles.monsterNavigation, styles.monsterNavigationRwd, props.isStartPage && styles.monsterNavigationRwdStartPage) },
            React.createElement(Container, { className: styles.monsterNavigationInside },
                React.createElement(Row, null,
                    React.createElement(Col, { md: 9, className: styles.leftGroupColumn },
                        React.createElement(Row, null, chunkedMenuLeftColumn.map(function (menuColumnGroup, idx) { return (React.createElement(Col, { xs: 4, sm: 4, key: idx, className: cn(idx === 2 && styles.lastLeftColumn) }, menuColumnGroup.map(function (menuColumn, sidx) { return (React.createElement(MenuColumn, { column: menuColumn, isActive: activeItem === menuColumn, openAccordion: openAccordionFunction(menuColumn), key: sidx })); }))); }))),
                    React.createElement(Col, { md: 3, className: styles.newsletterColumn },
                        React.createElement(Row, null, monsterNavigation === null || monsterNavigation === void 0 ? void 0 :
                            monsterNavigation.menuRightColumn.map(function (rightColumn, idx) { return (React.createElement(Col, { xs: 4, sm: 4, md: 11, key: idx },
                                React.createElement(MenuColumn, { column: rightColumn, isActive: activeItem === rightColumn, openAccordion: openAccordionFunction(rightColumn) }))); }), (_a = monsterNavigation === null || monsterNavigation === void 0 ? void 0 : monsterNavigation.menuContentArea) === null || _a === void 0 ? void 0 :
                            _a.map(function (block, idx) {
                                var _a;
                                return (React.createElement(Col, { xs: 4, sm: 4, md: 11, key: idx },
                                    React.createElement("div", { className: styles.naviGroup },
                                        React.createElement(RenderComponent, { contentLink: block.contentLink, key: idx + ((_a = monsterNavigation === null || monsterNavigation === void 0 ? void 0 : monsterNavigation.menuRightColumn) === null || _a === void 0 ? void 0 : _a.length) || 0, theme: "navigation" }))));
                            }))))),
            React.createElement("div", { className: styles.loginSection }, ((_b = props.languagePicker) === null || _b === void 0 ? void 0 : _b.value) && React.createElement(LanguagePicker, null)))));
}
export function MenuColumnHeader(props) {
    var styles = useStyles({});
    return (React.createElement("div", { className: styles.naviGroupHeader },
        props.icon ? (React.createElement(FAIcon, { icon: props.icon, className: styles.iconSmall })) : (React.createElement("div", { className: styles.iconSmallPlaceholder })),
        props.href ? (React.createElement("a", { href: props.href, target: props.target || "_self" }, props.text)) : (React.createElement("span", null, props.text)),
        props.isActive ? (React.createElement(FAIcon, { icon: "chevron-up", className: styles.icon })) : (React.createElement(FAIcon, { icon: "chevron-down", className: styles.icon }))));
}
function MenuColumn(props) {
    var styles = useStyles({});
    return (React.createElement("div", { className: styles.naviGroup },
        React.createElement("button", { role: "button", tabIndex: -1, className: cn(styles.naviGroupButton, props.isActive ? "active" : undefined), onClick: props.openAccordion },
            React.createElement(MenuColumnHeader, __assign({}, props.column, { isActive: props.isActive }))),
        React.createElement("ul", { className: cn(styles.naviGroupList, props.isActive ? "active" : undefined) }, props.column.children.map(function (i, cidx) { return (React.createElement("li", { key: cidx },
            React.createElement("a", { href: i.href, target: i.target }, i.text))); }))));
}
