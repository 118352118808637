import { brandColors } from "@basics/brand-colors";
import { FAIcon } from "@components/FAIcon/FAIcon";
import React from "react";
import { createUseStyles } from "react-jss";
import { userIcon } from "./commonStyles";
import cn from "classnames";
var useStyles = createUseStyles({
    userLink: {
        display: "contents",
    },
    userIcon: {
        extend: userIcon,
        color: function (_a) {
            var isMenuOpened = _a.isMenuOpened, isMenuCollapsed = _a.isMenuCollapsed;
            return isMenuOpened || isMenuCollapsed ? brandColors.black : brandColors.white;
        },
    },
});
export var UserLoginLinkWithIcon = function (_a) {
    var businessPortalLoginLink = _a.businessPortalLoginLink, isMenuOpened = _a.isMenuOpened, isMenuCollapsed = _a.isMenuCollapsed, className = _a.className;
    var styles = useStyles({ isMenuOpened: isMenuOpened, isMenuCollapsed: isMenuCollapsed });
    return (React.createElement("a", { href: businessPortalLoginLink, className: styles.userLink },
        React.createElement(FAIcon, { icon: "user", className: cn(styles.userIcon, className), "aria-controls": "login" })));
};
