var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, Row, onBefore, onBreakpoint, rem, } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { typography } from "@basics/typography";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { LocalizationContext, LocalizedLabel, localizedLabelString, } from "@hooks/LocalizationContext";
import { isDeviceResOrLower, useDeviceType } from "@hooks/useDeviceType";
import { RenderProperty } from "@/src/views/RenderProperty";
import { Accordion, AccordionItem } from "@components/Accordion/Accordion";
import { ContactListOverlay } from "@components/Block/ContactListBlock/ContactListOverlay";
import { LocationCard } from "@components/Block/ContactListBlock/LocationCard";
import { DynamicDownloadBlock } from "@components/Block/DynamicDownloadBlock";
import { ContactExpert } from "@components/ContactExpert/ContactExpert";
import { Header } from "@components/Header/Header";
import { RadioButton } from "@components/RadioButton/RadioButton";
import { Footer } from "@components/Shared/Footer";
import { namePropertyValue } from "@models/utils";
import { brandColors } from "@basics/brand-colors";
var useStyles = createUseStyles({
    locationOverview: __assign(__assign({ paddingTop: rem(spacings.m), paddingBottom: rem(spacings.l) }, onBreakpoint("sm", {
        paddingTop: rem(spacings.l),
        paddingBottom: rem(spacings.xl),
    })), onBreakpoint("md", {
        paddingTop: rem(spacings.xl),
        paddingBottom: rem(spacings.xxl),
    })),
    headline: __assign({}, typography.h1),
    accordion: onBefore("sm", {
        marginBottom: rem(spacings.l),
    }),
    countriesList: {
        padding: 0,
        listStyle: "none",
    },
    countryName: {
        "&$active $countryNameButton": {
            fontWeight: "bold",
        },
        "&:last-child $countryNameButton": {
            marginBottom: 0,
        },
    },
    countryNameButton: __assign(__assign({}, typography.textDefault), { width: "100%", textAlign: "left", background: "none", border: 0, padding: 0, color: brandColors.darkGrey, transition: "color 0.3s ease", "&:hover": {
            color: brandColors.black,
        }, "&:last-child": {
            marginBottom: rem(spacings.s),
        } }),
    countryHeadline: typography.h3,
    noResults: __assign(__assign({}, typography.textDefault), { textAlign: "center" }),
    active: {},
    visuallyHidden: __assign({}, typography.visuallyHidden),
});
export function LocationsOverviewPage(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var initialQueryParametrs = new URLSearchParams(window.location.search);
    var _o = useContext(GlobalContentStoreContext), getStartPageData = _o.getStartPageData, getImmediateStartPageData = _o.getImmediateStartPageData, getWebsiteData = _o.getWebsiteData;
    var localizationCtx = useContext(LocalizationContext);
    var websiteData = getWebsiteData();
    var _p = useState(getImmediateStartPageData()), startPageData = _p[0], setStartPageData = _p[1];
    var _q = useState(false), shouldRender = _q[0], setShouldRender = _q[1];
    var _r = useState(initialQueryParametrs.has("company")
        ? decodeURI(initialQueryParametrs.get("company") || "")
        : null), activeCompany = _r[0], setActiveCompany = _r[1];
    var COMPANY_OPTIONS = [
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "HdiGlobal",
                localizationCtx: localizationCtx,
            }),
            value: "hdi-global",
        },
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "HdiSpecialty",
                localizationCtx: localizationCtx,
            }),
            value: "hdi-specialty",
        },
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "HdiInsurance",
                localizationCtx: localizationCtx,
            }),
            value: "hdi-insurance",
        },
    ];
    var deviceType = useDeviceType();
    var isMobile = isDeviceResOrLower(deviceType, "mobile");
    var _s = props.data, pages = _s.pages, name = _s.name;
    var matchedUserRegion = (_b = (_a = pages.expandedValue) === null || _a === void 0 ? void 0 : _a.map(function (regions) { var _a; return (_a = regions.pages) === null || _a === void 0 ? void 0 : _a.expandedValue; })) === null || _b === void 0 ? void 0 : _b.findIndex(function (region) {
        return region === null || region === void 0 ? void 0 : region.some(function (country) {
            var _a, _b, _c;
            return ((_b = (_a = country.relatedPageLocale) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.toLowerCase()) ===
                ((_c = websiteData === null || websiteData === void 0 ? void 0 : websiteData.currentLanguage.link) === null || _c === void 0 ? void 0 : _c.toLowerCase());
        });
    });
    var matchedUserCountry = (_c = pages.expandedValue) === null || _c === void 0 ? void 0 : _c.flatMap(function (regions) { var _a; return (_a = regions.pages) === null || _a === void 0 ? void 0 : _a.expandedValue; }).find(function (country) {
        var _a, _b, _c;
        return ((_b = (_a = country === null || country === void 0 ? void 0 : country.relatedPageLocale) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.toLowerCase()) ===
            ((_c = websiteData === null || websiteData === void 0 ? void 0 : websiteData.currentLanguage.link) === null || _c === void 0 ? void 0 : _c.toLowerCase());
    });
    var queryCountryName = initialQueryParametrs.get("country");
    var possibleSelections = ((_e = (_d = pages.expandedValue) === null || _d === void 0 ? void 0 : _d.map(function (regions) { var _a; return (_a = regions.pages) === null || _a === void 0 ? void 0 : _a.expandedValue; })) === null || _e === void 0 ? void 0 : _e.flatMap(function (region, regionIdx) {
        var _a;
        return ((_a = region === null || region === void 0 ? void 0 : region.filter(function (country) {
            var _a, _b;
            return ((_b = (_a = country.relatedPageLocale) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.toLowerCase()) ===
                (queryCountryName === null || queryCountryName === void 0 ? void 0 : queryCountryName.toLowerCase());
        })) === null || _a === void 0 ? void 0 : _a.map(function (country) { return [regionIdx, country]; })) ||
            [];
    })) || [];
    var _t = (possibleSelections.length === 1 && possibleSelections[0]) || [
        undefined,
        undefined,
    ], queryUserRegion = _t[0], queryUserCountry = _t[1];
    var _u = useState(queryUserRegion), initialExpandedRegion = _u[0], setInitialExpandedRegion = _u[1];
    var _v = useState(queryUserCountry), activeCountry = _v[0], setActiveCountry = _v[1];
    var _w = useState(false), modalOpened = _w[0], setModalOpened = _w[1];
    var _x = useState(0), slide = _x[0], setSlide = _x[1];
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, []);
    useEffect(function () {
        if (startPageData && !activeCountry) {
            setInitialExpandedRegion(matchedUserRegion);
            setActiveCountry(matchedUserCountry);
        }
        setShouldRender(true);
    }, [startPageData]);
    useEffect(function () {
        var _a;
        var relatedPageLocale = (_a = activeCountry === null || activeCountry === void 0 ? void 0 : activeCountry.relatedPageLocale) === null || _a === void 0 ? void 0 : _a.value;
        var queryParameters = new URLSearchParams();
        relatedPageLocale && queryParameters.set("country", relatedPageLocale);
        activeCompany && queryParameters.set("company", activeCompany);
        history.replaceState({}, "", "".concat(window.location.pathname, "?").concat(queryParameters));
    }, [activeCountry, activeCompany]);
    function closeModal() {
        setModalOpened(false);
    }
    function openModal(slide) {
        setModalOpened(true);
        setSlide(slide);
    }
    var styles = useStyles();
    if (!startPageData || !shouldRender)
        return null;
    var contactExpertData = ((_f = props.data.contactsUseDefaultContent) === null || _f === void 0 ? void 0 : _f.value)
        ? startPageData
        : props.data;
    var filteredByCompany = (_h = (_g = activeCountry === null || activeCountry === void 0 ? void 0 : activeCountry.locations) === null || _g === void 0 ? void 0 : _g.expandedValue) === null || _h === void 0 ? void 0 : _h.filter(function (l) {
        var _a, _b;
        return ((_a = l.company.value) === null || _a === void 0 ? void 0 : _a.substr(0, l.company.value.indexOf(","))) ===
            activeCompany ||
            ((_b = l.company.value) === null || _b === void 0 ? void 0 : _b.substr(l.company.value.indexOf(",") + 1, l.company.value.length)) === activeCompany ||
            activeCompany === null;
    }).map(function (l, idx) { return (React.createElement(LocationCard, __assign({}, l, { itemNumber: idx, openModal: openModal, key: idx }))); });
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, __assign({}, startPageData)),
        React.createElement("main", { "data-testid": "LocationsOverviewPage" },
            React.createElement(Container, { className: styles.locationOverview },
                React.createElement("h2", { className: styles.headline },
                    React.createElement(RenderProperty, { value: name })),
                React.createElement(Row, null,
                    React.createElement(Col, { sm: 3 },
                        React.createElement(Accordion, { className: styles.accordion },
                            ((_j = props.data.enableCompanyFilter) === null || _j === void 0 ? void 0 : _j.value) && (React.createElement(AccordionItem, { headline: localizedLabelString({
                                    section: "JobListing",
                                    label: "Company",
                                    localizationCtx: localizationCtx,
                                }), initialIsActive: !isMobile },
                                React.createElement(RadioButton, { onCheck: function () { return setActiveCompany(null); }, checked: !activeCompany, label: localizedLabelString({
                                        section: "NewsWallPage",
                                        label: "AllItems",
                                        localizationCtx: localizationCtx,
                                    }) }),
                                COMPANY_OPTIONS.map(function (option) { return (React.createElement(RadioButton, { key: option.value, onCheck: function () { return setActiveCompany(option.value); }, checked: activeCompany === option.value, label: namePropertyValue(option.label) || "" })); }))), (_k = pages.expandedValue) === null || _k === void 0 ? void 0 :
                            _k.map(function (region, idx) {
                                var _a, _b;
                                return (React.createElement(AccordionItem, { headline: region.name, initialIsActive: initialExpandedRegion === idx, key: idx },
                                    React.createElement("ul", { className: styles.countriesList }, (_b = (_a = region.pages) === null || _a === void 0 ? void 0 : _a.expandedValue) === null || _b === void 0 ? void 0 : _b.map(function (country, idx) { return (React.createElement("li", { className: cn(styles.countryName, activeCountry === country
                                            ? styles.active
                                            : undefined), key: idx },
                                        React.createElement("button", { onClick: function () { return setActiveCountry(country); }, className: styles.countryNameButton },
                                            React.createElement(RenderProperty, { value: country.name })))); }))));
                            }))),
                    React.createElement(Col, { sm: 9 },
                        React.createElement("h3", { className: styles.countryHeadline },
                            React.createElement(RenderProperty, { value: activeCountry === null || activeCountry === void 0 ? void 0 : activeCountry.name })),
                        filteredByCompany.length > 0 ? (filteredByCompany) : (React.createElement("div", { className: styles.noResults },
                            React.createElement(LocalizedLabel, { section: "NewsWallPage", label: "NoResultsFound" })))))),
            React.createElement(ContactListOverlay, { contacts: (_l = activeCountry === null || activeCountry === void 0 ? void 0 : activeCountry.locations) === null || _l === void 0 ? void 0 : _l.expandedValue, modalOpened: modalOpened, closeModal: closeModal, initialSlide: slide }),
            React.createElement(RenderProperty, { value: props.data.additionalContent }),
            React.createElement(DynamicDownloadBlock, { downloadCategoriesFilter: props.data.downloadCategoriesFilter, downloadsManual: props.data.downloadsManual }),
            ((_m = props.data.contactCta) === null || _m === void 0 ? void 0 : _m.value) === "enabled" && (React.createElement(ContactExpert, __assign({}, contactExpertData)))),
        React.createElement(Footer, __assign({}, startPageData))));
}
