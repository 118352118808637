var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { createUseStyles } from "react-jss";
import { onBefore, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { brandColors } from "@basics/brand-colors";
import { FAIcon, FAIconStyles } from "./FAIcon/FAIcon";
var useStyles = createUseStyles({
    closeIcon: __assign(__assign({ position: "relative", color: brandColors.black, cursor: "pointer" }, FAIconStyles({
        width: 25,
        height: 25,
    })), onBefore("md", __assign({}, FAIconStyles({
        width: 20,
        height: 20,
    })))),
    button: {
        position: "absolute",
        top: rem(spacings.xs),
        right: rem(spacings.xs),
        border: "none",
        background: "white",
        "&:hover": {
            opacity: 1,
        },
        padding: rem(spacings.xxs),
        opacity: 0.5,
    },
});
export var CloseButton = function (_a) {
    var onClick = _a.onClick;
    var styles = useStyles({ isMenuOpened: true });
    var onClose = function () {
        onClick();
    };
    return (React.createElement("button", { className: styles.button, onClick: onClose, "data-testid": "close-button" },
        React.createElement("div", { "aria-hidden": true },
            React.createElement(FAIcon, { icon: "times", className: styles.closeIcon }))));
};
