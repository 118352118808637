var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import cn from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, onBreakpoint } from "@basics/layout";
import VisualHeaderCarousel from "@components/Block/VisualHeaderCarousel/VisualHeaderCarousel";
import { LanguagePicker } from "@components/LanguagePicker/LanguagePicker";
import { MonsterNavigation } from "./MonsterNavigation/MonsterNavigation";
import Search from "./Search/Search";
import { contactUsButtonWrapper, contactUsButtonWrapperShow, headerBottomContent, headerMixins, headerTop, headerTopContent, iconsWrapper, navLeft, navRight, } from "./commonStyles";
import { createUseStyles } from "react-jss";
import { brandColors } from "@basics/brand-colors";
import { pseudoSelectorShadow } from "@basics/mixins";
import { MenuLabelWithIcon } from "./MenuLabelWithIcon";
import { SearchIcon } from "./SearchIcon";
import { UserLoginLinkWithIcon } from "./UserLoginLinkWithIcon";
var START_PAGE_HEADER_TOP_CONTENT_HEIGHT = 48;
var useStyles = createUseStyles({
    header: function (_a) {
        var liftHeaderUpByPx = _a.liftHeaderUpByPx;
        return ({
            position: "sticky",
            top: 0,
            zIndex: 99,
            transition: "transform .3s ease",
            transform: "translateY(-".concat(liftHeaderUpByPx, "px)"),
        });
    },
    headerBottom: __assign(__assign(__assign({ display: "flex", alignItems: "center", height: 150, backgroundColor: "transparent", position: "absolute", width: "100%" }, onBreakpoint("sm", {
        height: 130,
        transition: "all .3s",
    })), onBreakpoint("md", {
        height: 180,
    })), { "&:after": __assign(__assign({}, pseudoSelectorShadow("0 4px 6px 0 rgba(0, 0, 0, 0.09)")), { height: 0, opacity: function (_a) {
                var isMenuOpened = _a.isMenuOpened, isSearchOpened = _a.isSearchOpened;
                return isMenuOpened || isSearchOpened ? 0 : 1;
            }, transition: "all .3s" }) }),
    headerCollapsed: __assign(__assign(__assign({ backgroundColor: brandColors.white, borderBottom: "1px solid ".concat(brandColors.lightGrey), height: 64, "&:after": {
            height: 12,
        } }, onBreakpoint("sm", {
        borderBottom: 0,
    })), onBreakpoint("md", {
        height: 80,
    })), { "& $logo": {
            left: 0,
            "& img": __assign(__assign({ width: 42, height: 17, padding: 0, backgroundColor: "transparent" }, onBreakpoint("sm", {
                width: 60,
                height: 24,
            })), onBreakpoint("md", {
                width: 72,
                height: 29,
            })),
        } }),
    logo: __assign(__assign({ display: "flex", position: "absolute", left: "calc((max((-1 * (1920px - min(100vw, 1504px))), (-1 * (100vw - min(100vw, 1504px)))) / 2) - 16px)" }, onBreakpoint("sm", {
        left: "calc((max((-1 * (1920px - min(100vw, 1504px))), (-1 * (100vw - min(100vw, 1504px)))) / 2) - 32px)",
        transition: "all .3s",
    })), { "& img": __assign(__assign({ width: 119, height: 67, padding: "0 21px 0 42px", backgroundColor: brandColors.white }, onBreakpoint("sm", {
            width: 166,
            height: 93,
            padding: "0 29px 0 59px",
            transition: "all .3s",
        })), onBreakpoint("md", {
            width: 241,
            height: 135,
            padding: "0 43px 0 86px",
        })) }),
    headerMixins: headerMixins,
    headerTop: headerTop,
    headerTopContent: {
        extend: headerTopContent,
        height: START_PAGE_HEADER_TOP_CONTENT_HEIGHT,
    },
    headerBottomContent: headerBottomContent,
    navLeft: navLeft,
    navRight: navRight,
    contactUsButtonWrapper: contactUsButtonWrapper,
    contactUsButtonWrapperShow: contactUsButtonWrapperShow,
    iconsWrapper: iconsWrapper,
});
export function StartPageHeader(props) {
    var _a, _b, _c;
    var topBar = props.topBar, searchField = props.searchField, metaNavigationLeft = props.metaNavigationLeft, metaNavigationRight = props.metaNavigationRight, languagePicker = props.languagePicker, logoLink = props.logoLink, logo = props.logo, userLogin = props.userLogin, businessPortalLoginLink = props.businessPortalLoginLink, navigationMenu = props.navigationMenu, homepageSearch = props.homepageSearch, homepageCarousel = props.homepageCarousel;
    var _d = useState(0), liftHeaderUpByPx = _d[0], setLiftHeaderUpByPx = _d[1];
    var menuToggler = useRef(null);
    var localeSuggestionWrapperRef = useRef(null);
    var scrollY = useRef(0);
    useScrollPosition(function (_a) {
        var _b, _c;
        var currPos = _a.currPos, prevPos = _a.prevPos;
        if (currPos.y === prevPos.y) {
            return;
        }
        var newVisibilityStatus = currPos.y < prevPos.y || currPos.y < 0 || prevPos.y < 0;
        var localeSuggestionWrapperHeight = ((_c = (_b = localeSuggestionWrapperRef === null || localeSuggestionWrapperRef === void 0 ? void 0 : localeSuggestionWrapperRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect()) === null || _c === void 0 ? void 0 : _c.height) ||
            0;
        setLiftHeaderUpByPx(newVisibilityStatus || !(topBar === null || topBar === void 0 ? void 0 : topBar.value)
            ? 0
            : localeSuggestionWrapperHeight +
                START_PAGE_HEADER_TOP_CONTENT_HEIGHT);
        scrollY.current = currPos.y;
        setHeaderElementToggle(function (s) { return ({
            openedMenu: s.openedMenu,
            openedSearch: s.openedSearch,
            menuCollapse: document.body.style.position === "static"
                ? currPos.y > 0
                : s.menuCollapse,
        }); });
    }, [liftHeaderUpByPx], undefined, true, 0);
    var _e = useState({
        openedMenu: false,
        openedSearch: false,
        menuCollapse: false,
    }), headerElementToggle = _e[0], setHeaderElementToggle = _e[1];
    var isSearchOpened = headerElementToggle.openedSearch;
    var isMenuOpened = headerElementToggle.openedMenu;
    var isSearchField = searchField.value;
    var isMenuCollapsed = headerElementToggle.menuCollapse;
    var menuToggle = useCallback(function () {
        setHeaderElementToggle(function (s) { return ({
            openedSearch: false,
            openedMenu: !s.openedMenu,
            menuCollapse: scrollY.current === 0 && !s.openedSearch
                ? !s.menuCollapse
                : s.menuCollapse,
        }); });
    }, [setHeaderElementToggle, scrollY]);
    var searchToggle = useCallback(function () {
        setHeaderElementToggle(function (s) { return ({
            openedSearch: !s.openedSearch,
            openedMenu: false,
            menuCollapse: scrollY.current === 0 && !s.openedMenu
                ? !s.menuCollapse
                : s.menuCollapse,
        }); });
    }, [setHeaderElementToggle, scrollY]);
    useEffect(function () {
        isMenuOpened || isSearchOpened
            ? (document.body.style.position = "fixed")
            : (document.body.style.position = "static");
    }, [isMenuOpened, isSearchOpened]);
    var searchRef = useRef(null);
    var handleClickOutside = useCallback(function (event) {
        if (menuToggler.current && menuToggler.current.contains(event.target)) {
            setHeaderElementToggle(function (s) { return ({
                openedMenu: s.openedMenu,
                openedSearch: false,
                menuCollapse: s.menuCollapse,
            }); });
        }
        else if (searchRef.current &&
            !searchRef.current.contains(event.target)) {
            isSearchOpened &&
                setHeaderElementToggle(function (s) { return ({
                    openedMenu: s.openedMenu,
                    openedSearch: false,
                    menuCollapse: false,
                }); });
        }
    }, [isSearchOpened, menuToggler, searchRef, setHeaderElementToggle]);
    useEffect(function () {
        document.addEventListener("click", handleClickOutside);
        return function () {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
    var styles = useStyles({
        liftHeaderUpByPx: liftHeaderUpByPx,
        isMenuOpened: isMenuOpened,
        isSearchOpened: isSearchOpened,
        isSearchField: isSearchField,
        isMenuCollapsed: isMenuCollapsed,
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("header", { className: cn(styles.header, styles.headerMixins), "data-testid": "header" },
            (topBar === null || topBar === void 0 ? void 0 : topBar.value) && (React.createElement("div", { className: styles.headerTop },
                React.createElement(Container, null,
                    React.createElement("div", { className: styles.headerTopContent },
                        React.createElement("nav", { className: styles.navLeft },
                            React.createElement("ul", null, (_a = metaNavigationLeft.value) === null || _a === void 0 ? void 0 : _a.map(function (l, idx) { return (React.createElement("li", { key: idx },
                                React.createElement("a", { href: l.href, title: l.title, target: l.target }, l.text))); }))),
                        React.createElement("nav", { className: styles.navRight },
                            React.createElement("ul", null, (_b = metaNavigationRight.value) === null || _b === void 0 ? void 0 : _b.map(function (l, idx) { return (React.createElement("li", { key: idx },
                                React.createElement("a", { href: l.href, title: l.title, target: l.target }, l.text))); })),
                            languagePicker.value && React.createElement(LanguagePicker, null)))))),
            React.createElement("div", { className: cn(styles.headerBottom, isMenuCollapsed ? styles.headerCollapsed : undefined) },
                React.createElement(Container, null,
                    React.createElement("div", { className: styles.headerBottomContent },
                        React.createElement("a", { href: logoLink.value ? logoLink.value : undefined, className: styles.logo },
                            React.createElement("img", { src: ((_c = logo.value) === null || _c === void 0 ? void 0 : _c.url) || undefined, alt: "logo" })),
                        React.createElement("div", { className: styles.iconsWrapper },
                            isSearchField && (React.createElement(SearchIcon, { isSearchOpened: isSearchOpened, isMenuCollapsed: isMenuCollapsed, isMenuOpened: isMenuOpened, searchToggle: searchToggle })),
                            (userLogin === null || userLogin === void 0 ? void 0 : userLogin.value) && businessPortalLoginLink.value && (React.createElement(UserLoginLinkWithIcon, { businessPortalLoginLink: businessPortalLoginLink.value, isMenuOpened: isMenuOpened, isMenuCollapsed: isMenuCollapsed })),
                            (navigationMenu === null || navigationMenu === void 0 ? void 0 : navigationMenu.value) && (React.createElement(MenuLabelWithIcon, { isMenuOpened: isMenuOpened, isMenuCollapsed: isMenuCollapsed, menuToggle: menuToggle, menuToggler: menuToggler })))))),
            React.createElement(MonsterNavigation, { isOpened: isMenuOpened, languagePicker: languagePicker, isStartPage: true }),
            React.createElement("div", { ref: searchRef },
                React.createElement(Search, { isOpened: isSearchOpened, searchPage: props.searchPage, homepageSearch: homepageSearch === null || homepageSearch === void 0 ? void 0 : homepageSearch.expandedValue, isStartPage: true }))),
        React.createElement(VisualHeaderCarousel, { items: homepageCarousel === null || homepageCarousel === void 0 ? void 0 : homepageCarousel.expandedValue, menuCollapsed: isMenuCollapsed })));
}
