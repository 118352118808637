var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cn from "classnames";
import isEqual from "lodash/isEqual";
import React from "react";
import { createUseStyles } from "react-jss";
import { fonts } from "@basics/fonts";
import { Container, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { styles } from "@basics/styles";
import { getRegionsForNavigation } from "./regionsExtentions";
import { brandColors } from "@basics/brand-colors";
export var RegionsBarNavigation = function (_a) {
    var regions = _a.regions, rest = __rest(_a, ["regions"]);
    var useStyles = createUseStyles({
        barNavigation: __assign(__assign({}, styles.pointer), { display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "32px", padding: "".concat(rem(spacings.m), " 0"), alignItems: "center", marginTop: "0px", marginBottom: "0px", textDecorationLine: "none", "& li": {
                listStyleType: "none",
                cursor: "pointer",
                fontSize: "1.1875rem",
                lineHeight: 1.5,
                "& a": __assign(__assign({ color: brandColors.black }, fonts.bold), { "&:hover": {
                        textDecorationLine: "underline",
                    } }),
            } }),
        background: {
            background: brandColors.lightGrey,
            width: "100%",
        },
    });
    var _b = useStyles(), barNavigation = _b.barNavigation, background = _b.background;
    var regionsToShow = getRegionsForNavigation(regions);
    return (React.createElement(Container, { isFluid: true, className: background },
        React.createElement(Container, null,
            React.createElement("ul", { className: barNavigation, "data-testid": "bar-navigation" }, regionsToShow.map(function (region, index) {
                return (React.createElement(NavigationItem, __assign({ key: JSON.stringify(region) + index, region: region }, rest)));
            })))));
};
var NavigationItem = function (_a) {
    var _b;
    var region = _a.region, selectedRegion = _a.selectedRegion, currentHoverRegion = _a.currentHoverRegion, onClick = _a.onClick, onMouseEnter = _a.onMouseEnter, onMouseLeave = _a.onMouseLeave;
    var useStyles = createUseStyles({
        selected: {
            textDecorationLine: "underline",
        },
    });
    var selected = useStyles().selected;
    var regionIsSelected = isEqual(selectedRegion, region);
    var regionIsHovered = isEqual(currentHoverRegion, region);
    var itemIsUnderlined = regionIsSelected ||
        (regionIsHovered && regionIsSelected) ||
        (regionIsHovered && !selectedRegion);
    return (React.createElement("li", null,
        React.createElement("a", { className: cn((_b = {},
                _b[selected] = itemIsUnderlined,
                _b)), onClick: function () {
                onClick === null || onClick === void 0 ? void 0 : onClick(region);
            }, onMouseEnter: function () {
                onMouseEnter === null || onMouseEnter === void 0 ? void 0 : onMouseEnter(region);
            }, onMouseLeave: function () {
                onMouseLeave === null || onMouseLeave === void 0 ? void 0 : onMouseLeave(region);
            } }, region.regionName)));
};
