var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { rem } from "@basics/layout";
import { createUseStyles } from "react-jss";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import cn from "classnames";
import { brandColors } from "@basics/brand-colors";
var useStyles = createUseStyles({
    element: {
        marginBottom: rem(spacings.s),
        display: "flex",
        position: "relative",
        "& input:checked + $customRadiobox::after": {
            opacity: 1,
        },
        "& input:focus + $customRadiobox": {
            outline: "2px solid ".concat(brandColors.lightBlue),
        },
    },
    customRadiobox: {
        content: '""',
        cursor: "pointer",
        height: 32,
        width: 32,
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: brandColors.white,
        border: "2px solid ".concat(brandColors.darkGrey),
        borderRadius: "100%",
        "&:after": {
            position: "absolute",
            content: '""',
            cursor: "pointer",
            border: "2px solid ".concat(brandColors.darkGrey),
            borderRadius: "100%",
            height: 32,
            width: 32,
            left: -2,
            top: -2,
            transition: "all .1s ease-out",
            background: brandColors.universalGreenHover,
            boxShadow: "inset 0 0 0 6px ".concat(brandColors.white),
            opacity: 0,
        },
    },
    label: __assign({}, customTypography(__assign(__assign({}, typography.textDefault), { marginLeft: rem(spacings.sam), marginBottom: 0, cursor: "pointer" }), {}, {
        marginLeft: rem(spacings.s),
        marginRight: rem(spacings.sam),
        marginBottom: 0,
    }, {
        marginLeft: rem(spacings.sam),
        marginRight: rem(spacings.m),
    })),
    strong: {
        fontWeight: "bold",
    },
    disabled: {
        "& div": {
            cursor: "not-allowed",
            borderColor: brandColors.mediumGrey,
            "&:after": {
                cursor: "not-allowed",
                borderColor: brandColors.mediumGrey,
            },
        },
        "& label": {
            color: brandColors.mediumGrey,
        },
    },
    checkbox: {
        height: 32,
        width: 32,
        flexShrink: 0,
        margin: 0,
        padding: 0,
        opacity: 0,
    },
});
export function RadioButton(props) {
    var styles = useStyles();
    return (React.createElement("label", { className: cn(styles.element, props.checked && styles.strong, props.disabled && styles.disabled, props.className) },
        React.createElement("input", { type: "radio", className: styles.checkbox, checked: props.checked, onChange: props.onCheck, disabled: props.disabled, onBlur: props.onBlur }),
        React.createElement("div", { className: styles.customRadiobox, onClick: props.onCheck }),
        React.createElement("span", { className: styles.label, onClick: props.onCheck }, props.label)));
}
