import { FAIcon } from "@components/FAIcon/FAIcon";
import React from "react";
import { createUseStyles } from "react-jss";
import { closeIcon, searchIcon } from "./commonStyles";
import { brandColors } from "@basics/brand-colors";
import cn from "classnames";
var useStyles = createUseStyles({
    searchIcon: {
        extend: searchIcon,
        color: function (_a) {
            var isMenuOpened = _a.isMenuOpened, isMenuCollapsed = _a.isMenuCollapsed;
            return isMenuOpened || isMenuCollapsed ? brandColors.black : brandColors.white;
        },
        marginLeft: "auto",
        alignSelf: function (_a) {
            var isSearchOpened = _a.isSearchOpened;
            return (isSearchOpened ? "center" : "");
        },
    },
    closeIcon: closeIcon,
});
export var SearchIcon = function (_a) {
    var _b;
    var isSearchOpened = _a.isSearchOpened, searchToggle = _a.searchToggle, isMenuCollapsed = _a.isMenuCollapsed, isMenuOpened = _a.isMenuOpened, className = _a.className;
    var styles = useStyles({ isSearchOpened: isSearchOpened, isMenuOpened: isMenuOpened, isMenuCollapsed: isMenuCollapsed });
    return (React.createElement(FAIcon, { icon: isSearchOpened ? "times" : "search", className: cn((_b = {},
            _b[styles.searchIcon] = !isSearchOpened,
            _b[styles.closeIcon] = isSearchOpened,
            _b), className), onClick: searchToggle, "aria-controls": "search" }));
};
