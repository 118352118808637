var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { createUseStyles } from "react-jss";
import { BREAKPOINTS, Col, Row, onBreakpoint, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { RenderProperty } from "@/src/views/RenderProperty";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
import { brandColors } from "@basics/brand-colors";
var useImageStyles = createUseStyles({
    imageBlock: __assign(__assign({ marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        display: "block",
        marginBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
    })),
    textContainer: {
        marginBottom: rem(spacings.s),
    },
    headline: __assign(__assign({}, typography.h2), { textAlign: "center" }),
    description: customTypography(__assign(__assign({}, typography.textDefault), { textAlign: "center" }), { marginBottom: rem(spacings.s) }, { marginBottom: rem(spacings.sam) }, { marginBottom: rem(spacings.m) }),
    image: { width: "100%", cursor: "default", padding: 0 },
    imageInner: {
        width: "100%",
        height: "100%",
        transform: "scale(1)",
        transition: "transform 0.3s ease",
    },
    imageCaption: {
        marginTop: rem(spacings.xxs),
        "& p": __assign(__assign({}, typography.textXSmall), { color: brandColors.grey }),
    },
    grayBg: __assign(__assign({ backgroundColor: function (_a) {
            var isGrayBg = _a.isGrayBg;
            return isGrayBg && brandColors.ultraLightGrey;
        }, padding: "".concat(spacings.m, " ").concat(spacings.sam, " ").concat(spacings.m) }, onBreakpoint("sm", {
        padding: "".concat(spacings.l, " ").concat(spacings.m, " ").concat(spacings.l),
    })), onBreakpoint("md", {
        padding: "".concat(spacings.xxl, " ").concat(spacings.l, " ").concat(spacings.xxl),
    })),
    bgRow: {
        maxWidth: BREAKPOINTS["xl"],
        margin: "0 auto",
    },
});
export var ImageComponent = function (props) {
    var _a, _b;
    var _c = props.data, image = _c.image, imageCaption = _c.imageCaption, title = _c.title, description = _c.description;
    var isGrayBg = ((_a = props.data.backgroundColor) === null || _a === void 0 ? void 0 : _a.value) === "gray";
    var styles = useImageStyles({ isGrayBg: isGrayBg });
    return (React.createElement("div", { className: isGrayBg ? styles.grayBg : undefined },
        ((title === null || title === void 0 ? void 0 : title.value) || (description === null || description === void 0 ? void 0 : description.value)) && (React.createElement(Row, { className: isGrayBg ? styles.bgRow : undefined },
            React.createElement(Col, { className: styles.textContainer },
                title.value && (React.createElement(SemanticHeader, { className: styles.headline, headerSize: (_b = props.data.headerSize) === null || _b === void 0 ? void 0 : _b.value },
                    React.createElement(RenderProperty, { value: title }))),
                description.value && (React.createElement("div", { className: styles.description },
                    React.createElement(RenderProperty, { value: description })))))),
        React.createElement(Row, null,
            React.createElement(Col, { sm: 10, smStart: 1, md: 8, mdStart: 2 },
                React.createElement(RenderProperty, { className: styles.imageInner, value: image }),
                imageCaption.value && (React.createElement(RenderProperty, { value: imageCaption, className: styles.imageCaption }))))));
};
