var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { fonts } from "@basics/fonts";
import { onBreakpoint, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { typography } from "@basics/typography";
import { Button } from "@components/Button/Button";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { brandColors } from "@basics/brand-colors";
var useAccordionStyles = createUseStyles({
    accordion: {
        margin: 0,
        borderTop: function (_a) {
            var noOuterBorder = _a.noOuterBorder;
            return noOuterBorder ? 0 : "2px solid ".concat(brandColors.lightGrey);
        },
        borderBottom: function (_a) {
            var noOuterBorder = _a.noOuterBorder;
            return noOuterBorder ? 0 : "2px solid ".concat(brandColors.lightGrey);
        },
        listStyle: "none",
        padding: 0,
    },
});
var useAccordionItemStyles = createUseStyles({
    accordion: {
        width: "100%",
    },
    title: __assign(__assign(__assign({}, typography.textDefault), fonts.bold), { margin: "0 !important", marginBottom: function (_a) {
            var active = _a.active;
            return (active ? rem(spacings.s) : 0);
        } }),
    icon: FAIconStyles({
        width: 24,
        height: 24,
    }),
    item: {
        "&:not(:first-child)": {
            borderTop: "2px solid ".concat(brandColors.lightGrey),
        },
    },
    itemContent: {
        maxHeight: function (_a) {
            var active = _a.active;
            return (active ? "unset" : 0);
        },
        opacity: function (_a) {
            var active = _a.active;
            return (active ? 1 : 0);
        },
        pointerEvents: function (_a) {
            var active = _a.active;
            return (active ? "all" : "none");
        },
        overflow: function (_a) {
            var active = _a.active;
            return (active ? "visible" : "hidden");
        },
        transition: "opacity 0.3s ease, max-height 0.3s ease",
        padding: function (_a) {
            var active = _a.active;
            return active ? "".concat(rem(spacings.xs), " 0 ").concat(rem(spacings.sam)) : 0;
        },
        "& *:last-child": {
            marginBottom: 0,
        },
    },
    toggler: __assign(__assign({ padding: function (_a) {
            var active = _a.active;
            return active
                ? "".concat(rem(spacings.m), " 0px ").concat(rem(spacings.s))
                : "".concat(rem(spacings.m), " 0px");
        } }, onBreakpoint("sm", {
        padding: function (_a) {
            var active = _a.active;
            return active ? "".concat(rem(spacings.s), " 0px 0px") : "".concat(rem(spacings.s), " 0px");
        },
    })), { border: "none", font: "inherit", color: "inherit", textAlign: "inherit", background: "none", display: "flex", gap: rem(spacings.xs), width: "100%", alignItems: "center", justifyContent: "space-between", cursor: "pointer" }),
    visuallyHidden: __assign({}, typography.visuallyHidden),
    button: {
        transition: "transform .3s",
        "& svg": {
            rotate: "-90deg",
        },
    },
    buttonActive: {
        transform: "rotate(90deg)",
    },
    buttonInactive: {
        transform: "rotate(0deg)",
    },
});
export function Accordion(props) {
    var styles = useAccordionStyles({ noOuterBorder: props.noOuterBorder });
    return (React.createElement("ul", { className: cn(props.className, styles.accordion), "data-testid": props["data-testid"] }, props.children));
}
export function AccordionItem(props) {
    var _a;
    var _b = useState(Boolean(props.initialIsActive)), active = _b[0], setActive = _b[1];
    var toggler = useRef(null);
    var styles = useAccordionItemStyles({ active: active });
    useEffect(function () {
        if (active && props.onActivate) {
            props.onActivate();
        }
    }, [active, props]);
    return (React.createElement("li", { className: cn(styles.item, props.className), "data-testid": "AccordionItem" },
        React.createElement("div", { role: "button", "aria-expanded": active, className: styles.toggler, onClick: function () {
                setActive(function (s) { return !s; });
            }, ref: toggler, onMouseUp: function () { return toggler.current && toggler.current.blur(); } },
            React.createElement("span", { className: styles.title }, props.headline),
            React.createElement("span", { className: styles.visuallyHidden }, active ? "(click to expand)" : "(click to shrink)"),
            React.createElement("span", { className: cn((_a = {},
                    _a[styles.buttonActive] = active,
                    _a[styles.buttonInactive] = !active,
                    _a), styles.button) },
                React.createElement(Button, { type: active ? "primary" : "secondary", shape: "square", animationDirection: active ? "right" : "up" },
                    React.createElement(FAIcon, { icon: active ? "minus" : "plus", className: styles.icon })))),
        React.createElement("div", { className: styles.itemContent, "aria-expanded": active, "data-testid": "AccordionItemContent" }, props.children)));
}
