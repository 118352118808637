var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { Input } from "@components/Input/Input";
import { Container, rem, Row, Col, onBreakpoint, onBefore, } from "@basics/layout";
import { fonts } from "@basics/fonts";
import { spacings } from "@basics/spacings";
import { Button } from "@components/Button/Button";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import cn from "classnames";
import { typography } from "@basics/typography";
import { brandColors } from "@basics/brand-colors";
var useStyles = createUseStyles({
    search: function (_a) {
        var isOpened = _a.isOpened;
        return (__assign(__assign({ backgroundColor: brandColors.white }, fonts.medium), { color: brandColors.black, position: "absolute", boxShadow: "0 1px 12px 0 rgba(0, 0, 0, 0.09)", width: "100%", overflowY: "auto", paddingTop: rem(spacings.m), paddingBottom: rem(spacings.l), zIndex: -1, transform: isOpened ? "scale(1,1)" : "scale(1,0)", transformOrigin: "center top", transition: (isOpened ? "transform 0.3s ease" : "transform 0.4s ease") +
                ", border-color 0.5s ease", transitionDelay: isOpened ? "0s" : "0.2s" }));
    },
    searchRwd: __assign({}, onBreakpoint("md", {
        paddingTop: "".concat(rem(spacings.l), " !important"),
    })),
    searchRwdStartPage: __assign({ paddingTop: "".concat(rem(spacings.m.times(3)), " !important") }, onBreakpoint("md", {
        paddingTop: "".concat(rem(spacings.xl.times(2)), " !important"),
    })),
    searchBar: {
        display: "flex",
        alignItems: "center",
        marginBottom: rem(spacings.m),
        "& label": __assign({ margin: "0 30px" }, onBefore("sm", {
            marginLeft: 0,
        })),
    },
    searchIcon: __assign(__assign({}, FAIconStyles({
        width: 25,
        height: 25,
    })), onBefore("sm", {
        display: "none",
    })),
    searchSuggestions: {
        "& ul": {
            padding: 0,
            "& li": {
                listStyle: "none",
                "& a": __assign(__assign({}, typography.textSmall), { color: brandColors.grey, fontSize: 17, marginBottom: 24, "&:hover": {
                        color: brandColors.black,
                    } }),
            },
        },
    },
});
export default function Search(props) {
    var _a, _b, _c;
    var _d = useState(), query = _d[0], setQuery = _d[1];
    function searchQuery() {
        var _a;
        if ((_a = props.searchPage) === null || _a === void 0 ? void 0 : _a.value)
            window.location.href = "".concat(props.searchPage.value.url, "?query=").concat(query);
    }
    var queryInputRef = useRef(null);
    function getValue(value) {
        setQuery(value);
    }
    var styles = useStyles({ isOpened: props.isOpened });
    return (React.createElement("nav", { id: "search", "aria-expanded": props.isOpened },
        React.createElement(Container, { isFluid: true, className: cn(styles.search, styles.searchRwd, props.isStartPage && styles.searchRwdStartPage) },
            React.createElement(Container, null,
                React.createElement(Row, null,
                    React.createElement(Col, { sm: 8, smStart: 2, md: 7, mdStart: 3 },
                        React.createElement("div", { className: styles.searchBar },
                            React.createElement(FAIcon, { icon: "search", className: styles.searchIcon }),
                            React.createElement(Input, { theme: "transparent", label: "Search", placeholder: ((_a = props.homepageSearch) === null || _a === void 0 ? void 0 : _a.placeholderText.value) || "", inputRef: queryInputRef, onSubmit: searchQuery, getValue: getValue }),
                            React.createElement(Button, { type: "primary", onClick: function () { return searchQuery(); } },
                                React.createElement(LocalizedLabel, { section: "Search", label: "Search" }))),
                        React.createElement("div", { className: styles.searchSuggestions },
                            React.createElement("ul", null, (_c = (_b = props.homepageSearch) === null || _b === void 0 ? void 0 : _b.searchSuggestions.value) === null || _c === void 0 ? void 0 : _c.map(function (s, idx) { return (React.createElement("li", { key: idx },
                                React.createElement("a", { href: s.href, target: s.target }, s.text))); })))))))));
}
