var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { brandColors } from "@basics/brand-colors";
import { onBreakpoint } from "@basics/layout";
import { typography } from "@basics/typography";
import React from "react";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import { closeIcon } from "./commonStyles";
var HEIGHT = 7;
var useStyles = createUseStyles({
    hamburgerIcon: {
        width: 21,
        height: 12,
        position: "relative",
        cursor: "pointer",
        "& div": __assign(__assign({ position: "absolute", height: 2, width: "100%", background: function (_a) {
                var isMenuOpened = _a.isMenuOpened, isMenuCollapsed = _a.isMenuCollapsed;
                return isMenuOpened || isMenuCollapsed ? brandColors.black : brandColors.white;
            }, opacity: 1, left: 0 }, onBreakpoint("sm", {
            transition: "all .3s",
        })), { "&:nth-child(1)": {
                top: function (_a) {
                    var isMenuOpened = _a.isMenuOpened;
                    return (isMenuOpened ? HEIGHT : 0);
                },
                left: function (_a) {
                    var isMenuOpened = _a.isMenuOpened;
                    return (isMenuOpened ? "50%" : 0);
                },
                width: function (_a) {
                    var isMenuOpened = _a.isMenuOpened;
                    return (isMenuOpened ? "0%" : "100%");
                },
            }, "&:nth-child(2)": {
                top: HEIGHT,
                transform: function (_a) {
                    var isMenuOpened = _a.isMenuOpened;
                    return isMenuOpened ? "rotate(45deg)" : "rotate(0deg)";
                },
            }, "&:nth-child(3)": {
                top: HEIGHT,
                transform: function (_a) {
                    var isMenuOpened = _a.isMenuOpened;
                    return isMenuOpened ? "rotate(-45deg)" : "rotate(0deg)";
                },
                opacity: function (_a) {
                    var isMenuOpened = _a.isMenuOpened;
                    return (isMenuOpened ? 1 : 0);
                },
            }, "&:nth-child(4)": {
                top: function (_a) {
                    var isMenuOpened = _a.isMenuOpened;
                    return isMenuOpened ? HEIGHT : HEIGHT * 2;
                },
                left: function (_a) {
                    var isMenuOpened = _a.isMenuOpened;
                    return (isMenuOpened ? "50%" : 0);
                },
                width: function (_a) {
                    var isMenuOpened = _a.isMenuOpened;
                    return (isMenuOpened ? "0%" : "100%");
                },
            } }),
    },
    mainMenu: {
        padding: 0,
        background: "none",
        display: "flex",
        gap: "12px",
        alignItems: "center",
        cursor: "pointer",
        "& span": __assign(__assign({ extend: typography.textLarge, marginBottom: 0, color: function (_a) {
                var isMenuOpened = _a.isMenuOpened, isMenuCollapsed = _a.isMenuCollapsed;
                return isMenuOpened || isMenuCollapsed ? brandColors.black : brandColors.white;
            }, display: "none" }, onBreakpoint("sm", {
            display: "block",
            transition: "all .3s",
            fontSize: 20,
            marginBottom: 0,
            lineHeight: 1.5,
        })), onBreakpoint("md", {
            fontSize: 24,
            marginBottom: 0,
            lineHeight: 1.5,
        })),
    },
    closeIcon: closeIcon,
});
export var MenuLabelWithIcon = function (_a) {
    var isMenuOpened = _a.isMenuOpened, isMenuCollapsed = _a.isMenuCollapsed, menuToggler = _a.menuToggler, menuToggle = _a.menuToggle, className = _a.className;
    var styles = useStyles({ isMenuOpened: isMenuOpened, isMenuCollapsed: isMenuCollapsed });
    return (React.createElement("button", { "aria-controls": "main-menu", className: cn(styles.mainMenu, className), ref: menuToggler, onClick: menuToggle, onMouseUp: function () { return menuToggler.current && menuToggler.current.blur(); } },
        React.createElement("span", null, "Menu"),
        React.createElement("div", { className: styles.hamburgerIcon, "aria-hidden": true },
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null))));
};
