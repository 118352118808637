var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createUseStyles } from "react-jss";
import { onBefore, onBreakpoint, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { FAIconStyles } from "@components/FAIcon/FAIcon";
import { brandColors } from "@basics/brand-colors";
export var personCardStyles = createUseStyles({
    contactCol: {
        marginBottom: rem(spacings.m),
    },
    animationWrapper: {
        height: "100%",
    },
    contact: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: brandColors.white,
        borderRadius: 10,
        cursor: "pointer",
        filter: "drop-shadow(0px 1px 7px rgba(0,0,0,.1))",
        transition: "filter 0.3s ease",
        "&:hover": {
            filter: "drop-shadow(0px 1px 7px rgba(0,0,0,.2))",
            transition: "filter 0.3s ease",
        },
    },
    bioSection: __assign(__assign({ display: "flex", backgroundColor: brandColors.ultraLightGrey, minHeight: 80, borderTopLeftRadius: 10, borderTopRightRadius: 10 }, onBreakpoint("sm", {
        minHeight: 96,
    })), onBreakpoint("md", {
        minHeight: 128,
    })),
    name: __assign(__assign(__assign({}, typography.h4), { marginLeft: rem(spacings.s), marginRight: "auto", marginTop: rem(spacings.s) }), onBreakpoint("md", {
        marginTop: rem(spacings.m),
        marginLeft: rem(spacings.m),
    })),
    photo: {
        "& img": __assign(__assign({ width: 80, height: 80, borderTopRightRadius: 10, marginLeft: rem(spacings.s), objectFit: "cover" }, onBreakpoint("sm", {
            width: 96,
            height: 96,
        })), onBreakpoint("md", {
            width: 128,
            height: 128,
        })),
    },
    infoSection: __assign({ display: "flex", flexDirection: "column", flexGrow: 1, padding: "".concat(rem(spacings.s), " ").concat(rem(spacings.s), " ").concat(rem(spacings.m)) }, onBreakpoint("md", {
        padding: "".concat(rem(spacings.sam), " ").concat(rem(spacings.m), " ").concat(rem(spacings.m)),
    })),
    role: __assign({}, customTypography(__assign(__assign({}, typography.textDefault), { color: brandColors.grey, marginBottom: 0 }), {}, {
        marginBottom: 0,
    })),
    defaultText: __assign({}, customTypography(__assign(__assign({}, typography.textDefault), { marginBottom: rem(spacings.xxs) }), {}, {
        marginBottom: rem(spacings.xxs),
    })),
    ctaButtons: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-end",
        marginTop: "auto",
        "& > button": {
            marginTop: rem(spacings.s),
            marginRight: rem(spacings.s),
        },
    },
    iconWrapper: __assign(__assign({ marginTop: rem(spacings.s), marginLeft: "auto" }, onBefore("sm", {
        display: "none",
    })), { "& button, & a": {
            backgroundColor: brandColors.lightGrey,
            transition: "color 0.3s ease, background-color 0.3s ease",
            padding: "11px 12px",
            borderRadius: "50%",
            marginRight: rem(spacings.s),
            "&:last-child": {
                marginRight: 0,
            },
            "&:hover": {
                color: brandColors.green,
                backgroundColor: brandColors.universalGreen,
                transition: "color 0.3s ease, background-color 0.3s ease",
            },
        } }),
    iconWrapperMobile: __assign(__assign({ marginTop: rem(spacings.s), marginLeft: "auto", display: "flex" }, onBreakpoint("sm", {
        display: "none",
    })), { "& button, & a": {
            backgroundColor: brandColors.lightGrey,
            "&$primaryCta": {
                backgroundColor: brandColors.universalGreen,
                color: brandColors.universalGreenHover,
            },
            padding: "11px 12px",
            borderRadius: "50%",
            marginRight: rem(spacings.xs),
            "&:last-child": {
                marginRight: 0,
            },
        } }),
    primaryCta: {},
    icon: __assign({}, FAIconStyles(__assign({ color: brandColors.darkGrey, width: 24, height: 24, cursor: "pointer" }, onBreakpoint("md", {
        width: 20,
        height: 20,
    })))),
    accessibilityText: typography.visuallyHidden,
    //for overlay only
    contacts: __assign(__assign(__assign({}, typography.textDefault), { marginTop: rem(spacings.m), marginBottom: 0, display: "flex" }), onBefore("sm", {
        marginTop: rem(spacings.s),
    })),
    contactLabels: {
        display: "flex",
        color: brandColors.grey,
        flexDirection: "column",
    },
    contactNumbers: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: rem(spacings.s),
        "& a": {
            color: brandColors.darkGrey,
        },
    },
    ctaButtonsOverlay: __assign({}, onBreakpoint("sm", {
        marginTop: "unset",
    })),
    linkedInIcon: __assign(__assign({ display: "flex", marginTop: "auto", alignSelf: "flex-end" }, onBefore("sm", {
        display: "none",
    })), { "& a": {
            marginTop: rem(spacings.sam),
            backgroundColor: brandColors.lightGrey,
            padding: "11px 12px",
            borderRadius: "50%",
            transition: "color 0.3s ease, background-color 0.3s ease",
            "&:hover": {
                color: brandColors.green,
                backgroundColor: brandColors.universalGreen,
                transition: "color 0.3s ease, background-color 0.3s ease",
            },
        } }),
});
