var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { rem } from "@basics/layout";
import { createUseStyles } from "react-jss";
import { spacings } from "@basics/spacings";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { fonts } from "@basics/fonts";
import { brandColors } from "@basics/brand-colors";
var useStyles = createUseStyles({
    element: {
        marginRight: rem(spacings.xs),
        marginBottom: rem(spacings.s),
        padding: "".concat(spacings.xs, " ").concat(spacings.xs, " ").concat(spacings.xs, " ").concat(spacings.s),
        backgroundColor: brandColors.grey,
        borderRadius: 30,
        display: "flex",
        alignItems: "center",
    },
    text: __assign(__assign({}, fonts.medium), { fontSize: "1rem", color: brandColors.white, paddingRight: rem(spacings.xs), textAlign: "left" }),
    button: {
        fill: brandColors.white,
        padding: 0,
        background: "none",
        display: "inline-flex",
        alignItems: "center",
    },
    icon: FAIconStyles({
        width: 24,
        height: 24,
    }),
});
export function TagBadge(props) {
    var styles = useStyles();
    var Element = props.href
        ? function (props) { return (React.createElement("a", __assign({}, props), props.children)); }
        : function (props) { return (React.createElement("button", __assign({ role: "button" }, props), props.children)); };
    return (React.createElement(Element, { className: styles.element, onClick: props.onBadgeClick, href: props.href },
        React.createElement("span", { className: styles.text },
            React.createElement("b", null, props.label)),
        !props.isReadOnly && (React.createElement("button", { role: "button", className: styles.button, onClick: props.onCloseClick },
            React.createElement(FAIcon, { icon: "times", className: styles.icon })))));
}
