var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import cn from "classnames";
import { createUseStyles } from "react-jss";
import { BREAKPOINTS, Col, Container, Row, onBreakpoint, rem, } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { RenderProperty } from "@/src/views/RenderProperty";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { ModalWithCarousel } from "@components/ModalWithCarousel/ModalWithCarousel";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
import { brandColors } from "@basics/brand-colors";
var imageStyles = createUseStyles({
    imageBlock: __assign(__assign({ marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        display: "block",
        marginBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
    })),
    textContainer: {
        marginBottom: rem(spacings.s),
    },
    headline: __assign(__assign({}, typography.h2), { textAlign: "center" }),
    description: customTypography(__assign(__assign({}, typography.textDefault), { textAlign: "center" }), { marginBottom: rem(spacings.s) }, { marginBottom: rem(spacings.sam) }, { marginBottom: rem(spacings.m) }),
    image: __assign(__assign({ display: "block", position: "relative", cursor: "pointer", overflow: "hidden", background: "none", border: 0, padding: 0, width: "100%" }, onBreakpoint("sm", {
        "&:after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: 0,
            transition: "opacity 0.3s ease",
            backgroundColor: brandColors.darkGreen,
        },
        "&:hover, &:focus": {
            "&:after": {
                opacity: 0.2,
                transition: "opacity 0.3s ease",
            },
            "& $zoomWatermark": {
                opacity: 1,
                transform: "translate(-50%, -50%)",
            },
            "& $imageInner": {
                transform: "scale(1.04)",
            },
        },
    })), { "& img": {
            width: "100%",
            display: "block",
        } }),
    imageInner: {
        width: "100%",
        height: "100%",
        transform: "scale(1)",
        transition: "transform 0.3s ease",
    },
    imageCaption: {
        marginTop: rem(spacings.xxs),
        "& p": __assign(__assign({}, typography.textXSmall), { color: brandColors.grey }),
    },
    zoomWatermark: __assign({}, FAIconStyles({
        position: "absolute",
        color: brandColors.ultraLightGrey,
        opacity: 0,
        width: 96,
        height: 96,
        transition: "transform 0.3s ease, opacity 0.3s ease",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -25%)",
        zIndex: 1,
    })),
    grayBg: __assign(__assign({ backgroundColor: function (isGrayBg) { return isGrayBg && brandColors.ultraLightGrey; }, padding: "".concat(spacings.m, " ").concat(spacings.sam, " ").concat(spacings.m) }, onBreakpoint("sm", {
        padding: "".concat(spacings.l, " ").concat(spacings.m, " ").concat(spacings.l),
    })), onBreakpoint("md", {
        padding: "".concat(spacings.xxl, " ").concat(spacings.l, " ").concat(spacings.xxl),
    })),
    bgRow: {
        maxWidth: BREAKPOINTS["xl"],
        margin: "0 auto",
    },
});
export function ImageBlock(props) {
    var _a, _b;
    var _c = props.data, image = _c.image, imageCaption = _c.imageCaption, title = _c.title, description = _c.description;
    var _d = useState(false), modalOpened = _d[0], setModalOpened = _d[1];
    var isGrayBg = ((_a = props.data.backgroundColor) === null || _a === void 0 ? void 0 : _a.value) === "gray";
    var styles = imageStyles(isGrayBg);
    var closeModal = function () {
        setModalOpened(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { isFluid: isGrayBg, className: cn(styles.imageBlock, isGrayBg ? styles.grayBg : undefined) },
            (title.value || description.value) && (React.createElement(Row, { className: isGrayBg ? styles.bgRow : undefined },
                React.createElement(Col, { sm: 10, smStart: 1, md: 8, mdStart: 2, className: styles.textContainer },
                    title.value && (React.createElement(SemanticHeader, { className: styles.headline, headerSize: (_b = props.data.headerSize) === null || _b === void 0 ? void 0 : _b.value },
                        React.createElement(RenderProperty, { value: title }))),
                    description.value && (React.createElement("div", { className: styles.description },
                        React.createElement(RenderProperty, { value: description })))))),
            React.createElement(Row, null,
                React.createElement(Col, { sm: 8, smStart: 2, md: 6, mdStart: 3 },
                    React.createElement("button", { className: styles.image, onClick: function () { return setModalOpened(true); } },
                        React.createElement(RenderProperty, { className: styles.imageInner, value: image }),
                        React.createElement(FAIcon, { icon: "search-plus", className: styles.zoomWatermark })),
                    imageCaption.value && (React.createElement(RenderProperty, { value: imageCaption, className: styles.imageCaption }))))),
        React.createElement(ModalWithCarousel, { images: [
                {
                    image: image.value,
                    description: imageCaption.value || "",
                },
            ], modalOpened: modalOpened, closeModal: closeModal })));
}
