var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { fonts } from "@basics/fonts";
import { Container, onBefore, onBreakpoint, rem } from "@basics/layout";
import { nonLocaleCompare } from "@basics/nonLocaleCompare";
import { spacings } from "@basics/spacings";
import { typography } from "@basics/typography";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { LocalizationContext, LocalizedLabel, localizedLabelString, } from "@hooks/LocalizationContext";
import { Button } from "@components/Button/Button";
import { FAIcon } from "@components/FAIcon/FAIcon";
import { setLocaleChosen } from "@components/LocaleOverlay/LocaleOverlay";
import { Select } from "@components/Select/Select";
import { brandColors } from "@basics/brand-colors";
var useStyles = createUseStyles({
    outerContainer: {
        padding: "12px 0",
        background: brandColors.lightBlue,
    },
    innerContainer: __assign({ display: "flex", alignItems: "center", flexWrap: "wrap" }, onBreakpoint("sm", {
        flexWrap: "nowrap",
    })),
    message: __assign(__assign(__assign({ width: "100%" }, fonts.medium), { fontSize: "0.875rem", color: brandColors.white, marginBottom: rem(spacings.s), textAlign: "center" }), onBreakpoint("sm", {
        width: "auto",
        marginBottom: 0,
        textAlign: "left",
        marginRight: rem(spacings.xl),
    })),
    controlsWrapper: __assign({ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }, onBreakpoint("sm", {
        justifyContent: "flex-start",
    })),
    languagesSelect: __assign(__assign(__assign({ width: "50%", flexGrow: 1, marginRight: rem(spacings.s), borderBottom: "2px solid ".concat(brandColors.ultraLightGrey, " !important") }, onBreakpoint("sm", {
        width: "320px",
        marginLeft: "auto",
        flexGrow: 0,
    })), onBreakpoint("md", {
        marginLeft: 0,
    })), { "& > div": {
            marginTop: 0,
        } }),
    acceptButton: {
        lineHeight: 1.5,
    },
    cancelButton: __assign({ fontSize: "1.35rem", lineHeight: 1, padding: "9px ".concat(spacings.s), background: "none", border: 0, color: brandColors.white }, onBefore("md", {
        marginRight: "-".concat(spacings.s),
    })),
    flagIcon: {
        height: "0.95rem",
    },
    visuallyHidden: __assign({}, typography.visuallyHidden),
});
/*
 * If the user closes the suggestion bar we remember the page language
 * he was on so that we don't suggest him to change it anymore
 */
var ACCEPTABLE_FOREIGN_LANGS_KEY = "acceptableForeignLangs";
export function getAcceptableUrlPrefixes() {
    var acceptableLangsString = Cookies.get(ACCEPTABLE_FOREIGN_LANGS_KEY) || "";
    return acceptableLangsString ? acceptableLangsString.split(",") : [];
}
export function addAcceptableUrlPrefix(languageCode) {
    var acceptableLangs = getAcceptableUrlPrefixes();
    if (!acceptableLangs.find(function (item) { return item === languageCode; })) {
        acceptableLangs.push(languageCode);
    }
    Cookies.set(ACCEPTABLE_FOREIGN_LANGS_KEY, acceptableLangs.join(","));
}
export function LocaleSuggestion() {
    var _a, _b, _c, _d;
    var styles = useStyles();
    var _e = useContext(GlobalContentStoreContext), getStartPageData = _e.getStartPageData, getImmediateStartPageData = _e.getImmediateStartPageData, getWebsiteData = _e.getWebsiteData;
    var websiteData = getWebsiteData();
    function getLocalesOptions(localeSelector) {
        var _a;
        var localesOptions = ((_a = localeSelector === null || localeSelector === void 0 ? void 0 : localeSelector.section.value) === null || _a === void 0 ? void 0 : _a.flatMap(function (region) { return region.locales; }).sort(function (a, b) { return nonLocaleCompare(a.label, b.label); }).filter(function (c) { return c.languageCode !== "en"; }).map(function (country) { return ({
            value: country.languageCode,
            label: country.label,
            icon: (React.createElement("img", { src: country.flagUrl, alt: "", className: styles.flagIcon })),
            url: country.externalUrl
                ? country.externalUrl
                : "/".concat(country.languageCode),
        }); })) || [];
        localesOptions.unshift({
            value: "international",
            label: "International website",
            icon: "globe",
            url: "/",
        });
        return localesOptions;
    }
    function acceptSelectedLanguage() {
        var localeOption = localesOptions.find(function (item) { return item.value === selectedUrlPrefix; });
        if (localeOption) {
            setLocaleChosen();
            addAcceptableUrlPrefix(localeOption.value);
            window.location.href = localeOption.url;
        }
        else {
            close();
        }
    }
    function close() {
        if (currentPageLanguageCode) {
            setLocaleChosen();
            addAcceptableUrlPrefix(currentPageLanguageCode);
        }
        setShouldDisplay(false);
    }
    var _f = useState(getImmediateStartPageData()), startPageData = _f[0], setStartPageData = _f[1];
    var basePreferredUrlPrefix = ((_a = window === null || window === void 0 ? void 0 : window.__LOCALE__) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || "";
    var stateLocalesOptions = getLocalesOptions((_b = startPageData === null || startPageData === void 0 ? void 0 : startPageData.localeSelector) === null || _b === void 0 ? void 0 : _b.expandedValue);
    var isBasePrefferedUrlPrefixIncorrect = !stateLocalesOptions.find(function (item) {
        return item.value.toLocaleLowerCase() ===
            basePreferredUrlPrefix.toLocaleLowerCase();
    });
    var preferredUrlPrefix = isBasePrefferedUrlPrefixIncorrect
        ? "international"
        : basePreferredUrlPrefix;
    var currentUrlPrefix = (_d = (_c = websiteData === null || websiteData === void 0 ? void 0 : websiteData.currentLanguage) === null || _c === void 0 ? void 0 : _c.link) === null || _d === void 0 ? void 0 : _d.toLowerCase();
    var isCurrentUrlPrefixInternational = websiteData === null || websiteData === void 0 ? void 0 : websiteData.currentLanguage.isMasterLanguage;
    var isCurrentUrlPrefixAcceptable = getAcceptableUrlPrefixes().find(function (item) {
        return item.toLowerCase() === currentUrlPrefix ||
            (item === "international" && isCurrentUrlPrefixInternational);
    });
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, []);
    useEffect(function () {
        setCurrentPageLanguageCode(isCurrentUrlPrefixInternational
            ? "international"
            : currentUrlPrefix || null);
        setLocalesOptions(stateLocalesOptions);
        setSelectedUrlPrefix(preferredUrlPrefix);
        setShouldDisplay(stateLocalesOptions.length > 0 &&
            preferredUrlPrefix !== currentUrlPrefix &&
            !(preferredUrlPrefix === "international" &&
                isCurrentUrlPrefixInternational) &&
            !isCurrentUrlPrefixAcceptable);
    }, [startPageData]);
    var _g = useState(isCurrentUrlPrefixInternational
        ? "international"
        : currentUrlPrefix || null), currentPageLanguageCode = _g[0], setCurrentPageLanguageCode = _g[1];
    var _h = useState(stateLocalesOptions.length > 0 &&
        preferredUrlPrefix !== currentUrlPrefix &&
        !(preferredUrlPrefix === "international" &&
            isCurrentUrlPrefixInternational) &&
        !isCurrentUrlPrefixAcceptable), shouldDisplay = _h[0], setShouldDisplay = _h[1];
    var _j = useState(stateLocalesOptions), localesOptions = _j[0], setLocalesOptions = _j[1];
    var _k = useState(preferredUrlPrefix), selectedUrlPrefix = _k[0], setSelectedUrlPrefix = _k[1];
    var localizationCtx = useContext(LocalizationContext);
    return shouldDisplay ? (React.createElement("aside", { className: styles.outerContainer },
        React.createElement(Container, { className: styles.innerContainer },
            React.createElement("div", { className: styles.message },
                React.createElement(LocalizedLabel, { section: "LocaleSuggestion", label: "Message" })),
            React.createElement("div", { className: styles.controlsWrapper },
                React.createElement(Select, { className: styles.languagesSelect, label: localizedLabelString({
                        localizationCtx: localizationCtx,
                        section: "LocaleSuggestion",
                        label: "SelectLabel",
                    }), options: localesOptions, selected: selectedUrlPrefix, setSelected: function (props) {
                        return setSelectedUrlPrefix(props);
                    } }),
                React.createElement(Button, { className: styles.acceptButton, onClick: function () { return acceptSelectedLanguage(); } }, "OK"),
                React.createElement("button", { className: styles.cancelButton, onClick: function () { return close(); } },
                    React.createElement("span", { className: styles.visuallyHidden },
                        React.createElement(LocalizedLabel, { section: "Global", label: "Cancel" })),
                    React.createElement(FAIcon, { icon: "times" })))))) : null;
}
