var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, onBreakpoint, rem, Row } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { PrivacyContext } from "@hooks/PrivacyStore";
import { useCurrentPage } from "@hooks/useCurrentPage";
import { useLocationHash } from "@hooks/useLocationHash";
import { AnimatedRenderProperty } from "@/src/views/RenderProperty";
import { Button } from "@components/Button/Button";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { PrivacyOverlay } from "@components/PrivacyOverlay/PrivacyOverlay";
import { brandColors } from "@basics/brand-colors";
var useStyles = createUseStyles({
    privacyNote: {
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        backgroundColor: brandColors.ultraLightGrey,
        borderTop: "1px solid ".concat(brandColors.lightGrey),
        padding: "".concat(spacings.m, " 0"),
        zIndex: 100,
    },
    text: __assign({}, customTypography(__assign(__assign({}, typography.textSmall), { marginBottom: 0, paddingLeft: rem(spacings.s), paddingRight: rem(spacings.s), display: "flex", alignItems: "flex-start" }), {}, {
        paddingLeft: 0,
        paddingRight: 0,
    }, {
        alignItems: "center",
        flex: 1,
    })),
    openOverlaySpan: {
        textDecoration: "underline",
        cursor: "pointer",
    },
    infoIcon: __assign({}, FAIconStyles({
        width: 60,
        height: 60,
        color: brandColors.universalGreen,
        marginRight: rem(spacings.sam),
    })),
    consentButton: __assign({ maxHeight: 46, flexBasis: "100%", justifyContent: "center", marginLeft: 0, marginBottom: rem(spacings.s), "&:last-child": {
            marginBottom: 0,
        } }, onBreakpoint("md", {
        flexBasis: "auto",
        marginLeft: rem(spacings.sam),
        marginBottom: 0,
    })),
    buttons: __assign(__assign({ display: "flex", alignItems: "center", justifyContent: "flex-end", flexWrap: "wrap", paddingLeft: rem(spacings.s), paddingRight: rem(spacings.s), paddingTop: rem(spacings.m), backgroundColor: brandColors.ultraLightGrey, width: "100%" }, onBreakpoint("sm", {
        width: "auto",
        justifyContent: "flex-start",
        paddingLeft: "calc(1em + ".concat(spacings.sam, ")"),
        paddingRight: 0,
        paddingTop: 0,
    })), onBreakpoint("md", {
        justifyContent: "flex-end",
        paddingLeft: 0,
        paddingTop: 0,
    })),
    flexibleBar: __assign({ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }, onBreakpoint("sm", {
        flexWrap: "nowrap",
    })),
});
export var showPrivacySettings = function (_a) {
    var cookiesAccepted = _a.cookiesAccepted, privacySettingsForced = _a.privacySettingsForced, blockConsentOverlay = _a.blockConsentOverlay;
    if (privacySettingsForced) {
        return true;
    }
    else {
        if (!cookiesAccepted && !blockConsentOverlay) {
            return true;
        }
        return false;
    }
};
export function PrivacyNote(props) {
    var _a;
    var privacyContext = useContext(PrivacyContext);
    var hash = useLocationHash();
    var currentPage = useCurrentPage();
    var styles = useStyles();
    var _b = useState(false), overlay = _b[0], setOverlay = _b[1];
    var forceShow = hash === "#open-privacy-overlay";
    var showNotificationBar = props.data.useNotificationBar.value && !overlay;
    if (!privacyContext || !privacyContext.isInitialized)
        return null;
    var showPrivacyNote = privacyContext.showPrivacyNote, allowRequired = privacyContext.allowRequired, allowAll = privacyContext.allowAll;
    var actionMap = new Map([
        ["accept_necessary", allowRequired],
        ["open_overlay", function () { return setOverlay(true); }],
        ["accept_all", allowAll],
    ]);
    var showSettings = showPrivacySettings({
        cookiesAccepted: !showPrivacyNote(),
        privacySettingsForced: forceShow,
        blockConsentOverlay: (currentPage === null || currentPage === void 0 ? void 0 : currentPage.blockConsentOverlay.value) || false,
    });
    if (!showSettings) {
        return null;
    }
    if (showNotificationBar) {
        return (React.createElement("div", { className: styles.privacyNote, "data-testid": "PrivacyNote" },
            React.createElement(Container, null,
                React.createElement(Row, null,
                    React.createElement(Col, { md: 12, className: styles.flexibleBar },
                        React.createElement("div", { className: styles.text },
                            React.createElement("div", null,
                                React.createElement(FAIcon, { icon: "info-circle", className: styles.infoIcon })),
                            React.createElement("div", null,
                                React.createElement(AnimatedRenderProperty, { value: props.data.barText }))),
                        React.createElement("div", { className: styles.buttons }, (_a = props.data.barButtons.value) === null || _a === void 0 ? void 0 : _a.map(function (button, idx) { return (React.createElement(Button, { key: idx, type: button.buttonType || "secondary", onClick: actionMap.get(button.function), className: styles.consentButton }, button.label)); })))))));
    }
    else {
        return (React.createElement(PrivacyOverlay, __assign({ isVisible: showSettings, close: function () {
                if (forceShow)
                    window.location.hash = "";
            } }, props.data)));
    }
}
