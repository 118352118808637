var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, MARGIN_OF_CONTAINER, Row, onBreakpoint, rem, } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { RenderProperty, ShowPropertyOnEdit } from "@/src/views/RenderProperty";
import { AnimatedRevealBlock } from "@components/AnimatedReveal/AnimatedReveal";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
import { brandColors } from "@basics/brand-colors";
var useStyles = createUseStyles({
    element: __assign(__assign({ marginBottom: rem(spacings.m), paddingTop: rem(spacings.m), paddingBottom: rem(spacings.m), position: "relative" }, onBreakpoint("sm", {
        marginBottom: rem(spacings.l),
        paddingTop: rem(spacings.l),
        paddingBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
        paddingTop: rem(spacings.xxl),
        paddingBottom: rem(spacings.xxl),
    })),
    background: {
        position: "absolute",
        backgroundColor: brandColors.ultraLightGrey,
        height: "100%",
        top: 0,
        width: "calc(2 * ".concat(MARGIN_OF_CONTAINER, " + 100%)"),
        left: "calc(-1 * ".concat(MARGIN_OF_CONTAINER, ")"),
    },
    textContainer: {
        marginBottom: rem(spacings.s),
    },
    headline: __assign(__assign({}, typography.h2), { textAlign: "center" }),
    description: customTypography(__assign(__assign({}, typography.textDefault), { textAlign: "center" }), { marginBottom: rem(spacings.s) }, { marginBottom: rem(spacings.sam) }, { marginBottom: rem(spacings.m) }),
    fullHeight: {
        height: "100%",
    },
});
export function FeaturedFactsBlock(props) {
    var _a, _b, _c, _d, _e, _f;
    var styles = useStyles((_a = props.data.facts.value) === null || _a === void 0 ? void 0 : _a.length);
    var factsLength = ((_b = props.data.facts.value) === null || _b === void 0 ? void 0 : _b.length) || 0;
    function isCenteredInXS(idx) {
        return idx === factsLength - 1 && idx % 2 === 0 && factsLength !== 1;
    }
    return (React.createElement(Container, { className: styles.element, dataTestid: "FeaturedFactsBlock" },
        React.createElement("div", { className: styles.background }),
        (((_c = props.data.headline) === null || _c === void 0 ? void 0 : _c.value) || ((_d = props.data.description) === null || _d === void 0 ? void 0 : _d.value)) && (React.createElement(Row, null,
            React.createElement(Col, { sm: 10, smStart: 1, md: 6, mdStart: 3, className: styles.textContainer },
                ShowPropertyOnEdit(props.data.headline) && (React.createElement(SemanticHeader, { className: styles.headline, headerSize: (_e = props.data.headerSize) === null || _e === void 0 ? void 0 : _e.value },
                    React.createElement(RenderProperty, { value: props.data.headline }))),
                ShowPropertyOnEdit(props.data.description) && (React.createElement("div", { className: styles.description },
                    React.createElement(RenderProperty, { value: props.data.description })))))),
        React.createElement(Row, null, (_f = props.data.facts.value) === null || _f === void 0 ? void 0 : _f.map(function (item, idx) { return (React.createElement(Col, { xs: factsLength > 1 ? 2 : 4, xsStart: isCenteredInXS(idx) ? 1 : undefined, sm: factsLength > 3 ? 3 : 4, smStart: (idx === 0
                ? Math.max((12 - 4 * factsLength) / 2, 0)
                : null), key: idx },
            React.createElement(AnimatedRevealBlock, { key: idx, revealDelay: idx * 200, className: styles.fullHeight },
                React.createElement(Card, __assign({}, item))))); }))));
}
var useCardStyles = createUseStyles({
    card: __assign(__assign({ backgroundColor: brandColors.white, padding: "".concat(spacings.m), display: "flex", flexDirection: "column", textAlign: "center", marginBottom: rem(spacings.s), height: "calc(100% - ".concat(spacings.s, ")") }, onBreakpoint("sm", {
        marginBottom: 0,
        height: "100%",
    })), { boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.1)" }),
    cardIcon: __assign(__assign(__assign(__assign({}, FAIconStyles({
        height: 56,
        width: 56,
    })), { alignSelf: "center", color: brandColors.universalGreen, marginBottom: rem(spacings.sam) }), onBreakpoint("sm", {
        marginBottom: rem(spacings.s),
    })), onBreakpoint("md", __assign(__assign({}, FAIconStyles({
        height: 88,
        width: 88,
    })), { marginBottom: rem(spacings.sam) }))),
    labelContainer: {
        marginTop: 0,
        marginBottom: 0,
    },
    label: __assign({}, customTypography(__assign(__assign({}, typography.h4), { textAlign: "center" }), {}, {
        marginBottom: rem(spacings.s),
    })),
    description: __assign({}, customTypography(__assign(__assign({}, typography.textDefault), { marginBottom: 0, textAlign: "center" }), {}, {
        marginBottom: 0,
    })),
});
function Card(props) {
    var styles = useCardStyles();
    return (React.createElement("div", { className: styles.card, "data-testid": "FeaturedFactsCard" },
        React.createElement(FAIcon, { icon: (props.icon || undefined), className: styles.cardIcon }),
        React.createElement("h4", { className: styles.labelContainer },
            React.createElement(RenderProperty, { value: props.label, className: styles.label })),
        React.createElement(RenderProperty, { value: props.description, className: styles.description })));
}
