var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import cn from "classnames";
import { CloudinaryVideoPlayer } from "@components/CloudinaryVideoPlayer/CloudinaryVideoPlayer";
import { AnimatedBlockRenderProperty, RenderProperty, } from "@/src/views/RenderProperty";
import { AnimatedRevealBlock } from "@components/AnimatedReveal/AnimatedReveal";
import { Container, Row, Col, CONTAINER_OFFSET_WIDTHS, MARGIN_OF_CONTAINER_LIMITED, rem, onBreakpoint, getGridColumnWidth, onBefore, } from "@basics/layout";
import { LocalizedLabel } from "@hooks/LocalizationContext";
import { Button } from "@components/Button/Button";
import { customTypography, typography } from "@basics/typography";
import { spacings } from "@basics/spacings";
import { createUseStyles } from "react-jss";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
import { fonts } from "@basics/fonts";
import { brandColors } from "@basics/brand-colors";
var useStyles = createUseStyles({
    textImageContainer: {
        marginTop: "calc(100vw / 16 * 10 - ".concat(spacings.l, ")"),
    },
    textVideoContainer: {
        marginTop: "calc(100vw / 16 * 9 - ".concat(spacings.l, ")"),
    },
    textContainer: __assign(__assign({}, onBreakpoint("sm", {
        marginTop: 0,
        position: "absolute",
        height: "100%",
        width: "100%",
        top: "calc(".concat(getGridColumnWidth("sm"), ")"),
    })), onBreakpoint("md", {
        top: "calc(".concat(getGridColumnWidth("md"), ")"),
    })),
    itemSectionColumn: {
        gridRow: 1,
        width: "100%",
        gridColumnStart: function (isEven) { return (isEven ? 1 : undefined); },
    },
    leftItem: __assign(__assign({}, onBreakpoint("sm", {
        marginLeft: -CONTAINER_OFFSET_WIDTHS["big"],
    })), onBreakpoint("md", {
        // INFO(mkarol): CSS Calc magic here :) We are moving to the left by value of margin:auto of container minus single padding
        marginLeft: "calc(-1 * ".concat(MARGIN_OF_CONTAINER_LIMITED, " - ").concat(CONTAINER_OFFSET_WIDTHS["big"], "px)"),
    })),
    itemSection: __assign(__assign({ display: "flex", flexDirection: "column", backgroundColor: brandColors.ultraLightGrey, padding: "".concat(spacings.l, " ").concat(spacings.sam) }, onBreakpoint("sm", {
        backgroundColor: "transparent",
        padding: function (isEven) {
            return isEven
                ? "".concat(spacings.l, " calc(").concat(getGridColumnWidth("sm"), ") ").concat(spacings.l, " ").concat(spacings.m)
                : "".concat(spacings.l, " ").concat(spacings.m, " ").concat(spacings.l, " calc(").concat(getGridColumnWidth("sm"), ")");
        },
    })), onBreakpoint("md", {
        padding: function (isEven) {
            return isEven
                ? "".concat(spacings.xxl, " calc(").concat(getGridColumnWidth("md"), ") ").concat(spacings.xxl, " 0")
                : "".concat(spacings.xxl, " 0 ").concat(spacings.xxl, " calc(").concat(getGridColumnWidth("md"), ")");
        },
    })),
    emptyBackgroundColumn: __assign(__assign({ gridRow: 1, display: "none" }, onBreakpoint("sm", {
        display: "block",
    })), { gridColumnStart: function (isEven) { return (isEven ? 1 : undefined); } }),
    emptyBackground: {
        backgroundColor: brandColors.ultraLightGrey,
        height: "100%",
        // INFO(mkarol): CSS Calc magic here :) We are adding the size of the margin:auto of container to the width plus single padding
        width: "calc(".concat(MARGIN_OF_CONTAINER_LIMITED, " + ").concat(CONTAINER_OFFSET_WIDTHS["big"], "px + 100%)"),
        position: "absolute",
    },
    h4: __assign(__assign({}, typography.h4), { wordBreak: "break-word" }),
    textDefault: customTypography(__assign(__assign(__assign(__assign({}, typography.textDefault), typography.grayBackgroundFix), fonts.book), { position: "absolute", height: "100%", overflow: "hidden", "& p": __assign({}, fonts.book) }), { marginBottom: 0 }, { marginBottom: 0 }, { marginBottom: 0 }),
    teaserItem: __assign(__assign({}, onBefore("sm", {
        position: "absolute",
        width: "100vw",
        marginLeft: -CONTAINER_OFFSET_WIDTHS["small"],
    })), onBreakpoint("sm", {
        width: "calc(".concat(MARGIN_OF_CONTAINER_LIMITED, " + ").concat(CONTAINER_OFFSET_WIDTHS["big"], "px + 100%)"),
    })),
    button: {
        alignSelf: "flex-start",
    },
    icon: {
        marginLeft: rem(spacings.s),
    },
    fullHeight: {
        height: "100%",
    },
    descriptionBox: __assign(__assign(__assign({ display: "none", position: "relative", flex: 1 }, onBreakpoint("sm", {
        display: "block",
        marginBottom: rem(spacings.m),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xl),
    })), { "&:after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: rem(spacings.m.times(2)),
            background: "linear-gradient(to bottom, ".concat(brandColors.ultraLightGrey, "00, ").concat(brandColors.ultraLightGrey, "FF 100%)"),
        } }),
});
export function FeaturedContent(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var isVideo = function (dataBlock) {
        return typeof dataBlock.videoBlockArea !== "undefined";
    };
    var _m = useState(false), forceVideoPlay = _m[0], setForceVideoPlay = _m[1];
    var isRight = (isVideo(props.data)
        ? props.data.videoAlignment.value
        : props.data.imageAlignment.value) === "right";
    var styles = useStyles(isRight);
    var link = !isVideo(props.data) ? (_a = props.data.link.value) === null || _a === void 0 ? void 0 : _a[0] : undefined;
    var videoBlock = isVideo(props.data) && ((_b = props.data.videoBlockArea.expandedValue) === null || _b === void 0 ? void 0 : _b[0])
        ? props.data.videoBlockArea.expandedValue[0]
        : undefined;
    var isAutoplay = ((_c = videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.startAction) === null || _c === void 0 ? void 0 : _c.value) === "autoplay";
    var isLoop = ((_d = videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.endAction) === null || _d === void 0 ? void 0 : _d.value) === "loop_video";
    var resetOnEnd = ((_e = videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.endAction) === null || _e === void 0 ? void 0 : _e.value) === "reset_to_thumbnail";
    var defaultPosterUrl = ((_f = videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.thumbnail.value) === null || _f === void 0 ? void 0 : _f.urlLarge) ||
        ((_g = videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.thumbnail.value) === null || _g === void 0 ? void 0 : _g.urlMedium) ||
        ((_h = videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.thumbnail.value) === null || _h === void 0 ? void 0 : _h.urlSmall);
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement(Row, null,
                React.createElement(Col, { sm: 9, smStart: isRight ? 3 : undefined }, isVideo(props.data) ? (React.createElement("div", { className: cn(styles.teaserItem, !isRight && styles.leftItem) },
                    React.createElement(CloudinaryVideoPlayer, { publicId: ((_j = videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.cloudinaryVideoPublicID) === null || _j === void 0 ? void 0 : _j.value) || "", logoImageUrl: ((_k = props.startPageData.logo.value) === null || _k === void 0 ? void 0 : _k.url) || "", showControls: false, autoplay: isAutoplay, loop: isLoop, resetVideo: resetOnEnd, thumbnailUrl: defaultPosterUrl || undefined, withOverlay: true, forcePlay: forceVideoPlay, setForcePlay: setForceVideoPlay }))) : (React.createElement("div", { className: cn(styles.teaserItem, !isRight && styles.leftItem) },
                    React.createElement(AnimatedBlockRenderProperty, { value: props.data.imageAdaptive })))))),
        React.createElement(AnimatedRevealBlock, { className: cn(styles.textContainer, isVideo(props.data)
                ? styles.textVideoContainer
                : styles.textImageContainer), revealDelay: 100 },
            React.createElement(Container, { className: styles.fullHeight },
                React.createElement(Row, { className: styles.fullHeight },
                    React.createElement(Col, { xs: 4, sm: 4, smStart: isRight ? undefined : 8, className: cn(styles.emptyBackgroundColumn, styles.fullHeight) },
                        React.createElement("div", { className: cn(styles.emptyBackground, isRight && styles.leftItem) })),
                    React.createElement(Col, { xs: 4, sm: 4, smStart: isRight ? undefined : 8, className: cn(styles.itemSectionColumn, styles.fullHeight) },
                        React.createElement("div", { className: cn(styles.itemSection, styles.fullHeight) },
                            React.createElement(SemanticHeader, { className: styles.h4, headerSize: (_l = props.data.headerSize) === null || _l === void 0 ? void 0 : _l.value },
                                React.createElement(RenderProperty, { value: props.data.headline, fallbackValue: videoBlock === null || videoBlock === void 0 ? void 0 : videoBlock.headline })),
                            React.createElement("div", { className: styles.descriptionBox },
                                React.createElement("p", { className: styles.textDefault },
                                    React.createElement(RenderProperty, { value: props.data.descriptionRichText }))),
                            isVideo(props.data) ? (React.createElement(Button, { type: "secondary", onClick: function () { return setForceVideoPlay(true); }, className: styles.button, icon: "play" },
                                React.createElement(LocalizedLabel, { section: "Global", label: "PlayVideo" }))) : (link && (React.createElement(Button, { type: "secondary", href: link.href, title: link.title, target: link.target, className: styles.button }, link.text))))))))));
}
