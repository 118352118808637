var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import cn from "classnames";
import React from "react";
import { createUseStyles } from "react-jss";
import { BREAKPOINTS, Col, Container, onBefore, onBreakpoint, rem, Row, } from "@basics/layout";
import { pseudoSelectorShadow } from "@basics/mixins";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { isDeviceResOrHigher, useDeviceType } from "@hooks/useDeviceType";
import { AnimatedRenderProperty, RenderProperty, ShowPropertyOnEdit, } from "@/src/views/RenderProperty";
import { AnimatedRevealInline } from "@components/AnimatedReveal/AnimatedReveal";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { SemanticHeader } from "@components/SemanticHeader/SematicHeader";
import { useSlider } from "@components/Slider/Slider";
import { brandColors } from "@basics/brand-colors";
var useStyles = createUseStyles({
    featuredPages: __assign(__assign({ marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        display: "block",
        marginBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
    })),
    textContainer: {
        marginBottom: rem(spacings.s),
    },
    headline: __assign(__assign({}, typography.h2), { textAlign: "center" }),
    description: customTypography(__assign(__assign({}, typography.textDefault), { textAlign: "center" }), { marginBottom: rem(spacings.s) }, { marginBottom: rem(spacings.sam) }, { marginBottom: rem(spacings.m) }),
    cardCarousel: __assign(__assign({ width: function (_a) {
            var itemsAmount = _a.itemsAmount;
            return itemsAmount < 5 ? undefined : "calc(100% - 80px)";
        } }, onBefore("md", {
        width: function () { return "auto"; },
    })), { margin: "0 auto", "& .slick-list": {
            padding: "".concat(spacings.xs, " 0"),
        }, "& .slick-track": {
            display: "flex",
            marginBottom: rem(spacings.l),
        }, "& .slick-slide": {
            height: "auto",
            "& > div": {
                height: "100%",
                margin: "0 ".concat(spacings.xs.add(spacings.xxs)),
            },
        }, "& .slick-arrow": {
            width: 48,
            height: 48,
            "&:before": {
                color: brandColors.universalGreen,
                fontSize: "3rem",
            },
        }, "& .slick-prev": {
            left: -50,
            "&:before": {
                fontFamily: "Font Awesome\\ 5 Pro",
                content: '"\\f053"',
            },
        }, "& .slick-next": {
            right: -50,
            "&:before": {
                fontFamily: "Font Awesome\\ 5 Pro",
                content: '"\\f054"',
            },
        }, "& .slick-dots": {
            bottom: -10,
            "& button:before, & .slick-active button:before": {
                fontFamily: "Font Awesome\\ 5 Pro",
                content: '"\\f111"',
                fontWeight: 900,
                fontSize: "1rem",
                color: brandColors.universalGreen,
            },
        } }),
    grayBg: __assign(__assign({ backgroundColor: function (_a) {
            var isGrayBg = _a.isGrayBg;
            return isGrayBg && brandColors.ultraLightGrey;
        }, padding: "".concat(spacings.m, " ").concat(spacings.sam, " ").concat(spacings.m) }, onBreakpoint("sm", {
        padding: "".concat(spacings.l, " ").concat(spacings.m, " ").concat(spacings.l),
    })), onBreakpoint("md", {
        padding: "".concat(spacings.xxl, " ").concat(spacings.l, " ").concat(spacings.xxl),
    })),
    bgRow: {
        maxWidth: BREAKPOINTS["xl"],
        margin: "0 auto",
    },
});
export function FeaturedPagesBlock(props) {
    var _a, _b, _c;
    var _d = props.data, headline = _d.headline, description = _d.description, pageList = _d.pageList;
    var deviceType = useDeviceType();
    function slidesToShow() {
        switch (deviceType) {
            case "mobile":
                return 2;
            case "tablet":
            case "desktop":
                return 3;
            default:
                return 4;
        }
    }
    var carouselSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow(),
        slidesToScroll: 1,
        arrows: isDeviceResOrHigher(deviceType, "desktop"),
    };
    var isGrayBg = ((_a = props.data.backgroundColor) === null || _a === void 0 ? void 0 : _a.value) === "gray";
    var styles = useStyles({
        isGrayBg: isGrayBg,
        itemsAmount: (_b = pageList.value) === null || _b === void 0 ? void 0 : _b.length,
    });
    var Slider = useSlider();
    return (React.createElement(Container, { isFluid: isGrayBg, className: cn(styles.featuredPages, isGrayBg ? styles.grayBg : undefined) },
        (headline.value || description.value) && (React.createElement(Row, { className: isGrayBg ? styles.bgRow : undefined },
            React.createElement(Col, { sm: 10, smStart: 1, md: 8, mdStart: 2, className: styles.textContainer },
                ShowPropertyOnEdit(headline) && (React.createElement(SemanticHeader, { className: styles.headline, headerSize: (_c = props.data.headerSize) === null || _c === void 0 ? void 0 : _c.value },
                    React.createElement(RenderProperty, { value: headline }))),
                ShowPropertyOnEdit(description) && (React.createElement("div", { className: styles.description },
                    React.createElement(RenderProperty, { value: description })))))),
        React.createElement(Row, { className: isGrayBg ? styles.bgRow : undefined },
            React.createElement(Col, { style: { width: "100%" /* due to FF */ } }, Slider && (React.createElement(Slider, __assign({}, carouselSettings, { className: styles.cardCarousel }), pageList.expandedValue.map(function (page, idx) { return (React.createElement(Card, __assign({}, page, { key: idx, revealDelay: idx >= carouselSettings.slidesToShow ? -1 : 100 * idx }))); })))))));
}
var useCardStyles = createUseStyles({
    cardWrapper: {
        height: "100%",
    },
    card: __assign({ display: "flex", height: "100%", justifyContent: "center", flexWrap: "wrap", textAlign: "center", backgroundColor: brandColors.white, padding: "".concat(rem(spacings.m.add(spacings.xxs)), " ").concat(spacings.s, " ").concat(spacings.m), transition: "background-color 0.3s ease", position: "relative", "&:after": __assign(__assign({}, pseudoSelectorShadow("0 2px 12px 0 rgba(0, 0, 0, 0.3)")), { opacity: 0.5, transition: "opacity 0.3s ease" }), "&:hover": {
            "&:after": {
                opacity: 1,
                transition: "opacity 0.3s ease",
            },
            "& $cardIcon": {
                transform: "scale(1.1)",
                transition: "transform 0.3s ease",
            },
        } }, onBreakpoint("md", {
        padding: "".concat(rem(spacings.xl), " ").concat(spacings.m, " ").concat(spacings.xxl),
    })),
    cardIcon: __assign({ color: brandColors.universalGreen, flexBasis: "100%", marginBottom: rem(spacings.xl), transform: "scale(1)", transition: "transform 0.3s ease" }, FAIconStyles(__assign({ width: 56, height: 56 }, onBreakpoint("md", {
        width: 88,
        height: 88,
    })))),
    cardTitle: customTypography(__assign(__assign({}, typography.h4), { hyphens: "auto", wordBreak: "brake-all" }), { marginBottom: 0 }, { marginBottom: 0 }, { marginBottom: 0 }),
});
function Card(props) {
    var styles = useCardStyles();
    return (React.createElement(AnimatedRevealInline, { revealDelay: props.revealDelay, className: styles.cardWrapper },
        React.createElement("a", { href: props.url || "", className: styles.card },
            React.createElement(FAIcon, { icon: props.metaIcon.value || "link", className: styles.cardIcon }),
            React.createElement("h4", { className: styles.cardTitle },
                React.createElement(AnimatedRenderProperty, { value: props.name, revealDelay: props.revealDelay })))));
}
