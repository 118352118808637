var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { createUseStyles } from "react-jss";
import { Col, Container, onBefore, onBreakpoint, rem, Row, } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { AnimatedBlockRenderProperty, RenderProperty, } from "@/src/views/RenderProperty";
import { typography } from "@basics/typography";
import cn from "classnames";
import { AnimatedRevealBlock } from "@components/AnimatedReveal/AnimatedReveal";
import { brandColors } from "@basics/brand-colors";
var useStyles = createUseStyles({
    quote: __assign(__assign({ marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        display: "block",
        marginBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
    })),
    description: typography.h3,
    author: __assign(__assign({}, typography.textDefault), { display: "flex", flexDirection: "column", marginBottom: 0, "& span": {
            "&:last-child": {
                color: brandColors.grey,
            },
        } }),
    textContainer: __assign(__assign(__assign({ minHeight: 230, padding: rem(spacings.m), paddingRight: 0, backgroundColor: brandColors.ultraLightGrey, "@media (min-width: 500px)": {
            paddingRight: rem(spacings.m),
        } }, onBreakpoint("sm", {
        marginTop: rem(spacings.l),
        padding: rem(spacings.l),
    })), onBreakpoint("md", {
        marginTop: rem(spacings.xxl),
        padding: rem(spacings.xxl),
        paddingRight: rem(spacings.l),
    })), { "&:after": __assign({ content: '""', position: "absolute", top: 0, right: "-25%", width: "100%", height: "100%", backgroundColor: brandColors.ultraLightGrey, zIndex: -1 }, onBreakpoint("sm", {
            right: "-15%",
        })) }),
    narrow: __assign(__assign({}, onBreakpoint("sm", {
        "&:after": {
            right: "-12%",
        },
    })), onBreakpoint("md", {
        "&:after": {
            right: "-35%",
        },
    })),
    textContainerRight: __assign(__assign({ "&:after": {
            left: "-25%",
        } }, onBreakpoint("sm", {
        "&:after": {
            left: "-15%",
        },
        "&$narrow": {
            "&:after": {
                left: "-12%",
            },
        },
    })), onBreakpoint("md", {
        paddingLeft: rem(spacings.l),
        paddingRight: rem(spacings.xxl),
        "&$narrow": {
            "&:after": {
                left: "-35%",
            },
        },
    })),
    imageContainer: __assign(__assign({ position: "absolute", bottom: 32, width: "100%", marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        width: "auto",
        position: "static",
        marginBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.m.times(2)),
    })),
    imageContainerRight: __assign({}, onBefore("sm", {
        gridColumnStart: 1,
    })),
});
export function QuoteBlock(props) {
    var _a = props.data, additionalInformation = _a.additionalInformation, image = _a.image, imageAlignment = _a.imageAlignment, layout = _a.layout, quote = _a.quote, source = _a.source;
    var styles = useStyles();
    var isNarrow = layout.value === "narrow";
    var imageOnRight = (imageAlignment === null || imageAlignment === void 0 ? void 0 : imageAlignment.value) === "right";
    return (React.createElement(Container, { className: styles.quote, dataTestid: "QuoteBlock" },
        React.createElement(Row, { style: { position: "relative" } }, imageOnRight ? (React.createElement(React.Fragment, null,
            React.createElement(Col, { xs: 3, sm: isNarrow ? 9 : 7, md: isNarrow ? 6 : 7, mdStart: isNarrow ? 1 : null, className: cn(styles.textContainer, isNarrow && styles.narrow) },
                React.createElement(AnimatedRevealBlock, null,
                    React.createElement(RenderProperty, { value: quote, className: styles.description }),
                    React.createElement("div", { className: styles.author },
                        React.createElement(RenderProperty, { value: source }),
                        React.createElement(RenderProperty, { value: additionalInformation })))),
            React.createElement(Col, { xs: 1, xsStart: 3, sm: isNarrow ? 3 : 5, smStart: isNarrow ? 9 : 7, md: isNarrow ? 4 : 5, mdStart: 7, className: styles.imageContainer },
                React.createElement(AnimatedBlockRenderProperty, { value: image, revealDelay: 200 })))) : (React.createElement(React.Fragment, null,
            React.createElement(Col, { xs: 1, sm: isNarrow ? 3 : 5, md: isNarrow ? 4 : 5, mdStart: isNarrow ? 1 : null, className: cn(styles.imageContainer, styles.imageContainerRight) },
                React.createElement(AnimatedBlockRenderProperty, { value: image, revealDelay: 200 })),
            React.createElement(Col, { xs: 3, xsStart: 1, sm: isNarrow ? 9 : 7, smStart: isNarrow ? 3 : 5, md: isNarrow ? 6 : 7, mdStart: 5, className: cn(styles.textContainer, isNarrow && styles.narrow, styles.textContainerRight) },
                React.createElement(AnimatedRevealBlock, null,
                    React.createElement(RenderProperty, { value: quote, className: styles.description }),
                    React.createElement("div", { className: styles.author },
                        React.createElement(RenderProperty, { value: source }),
                        React.createElement(RenderProperty, { value: additionalInformation })))))))));
}
