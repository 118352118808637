var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useContext, useState, useEffect } from "react";
import { Header } from "@components/Header/Header";
import { Footer } from "@components/Shared/Footer";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { Container, Row, Col, onBreakpoint, rem, onBefore, } from "@basics/layout";
import { createUseStyles } from "react-jss";
import { spacings } from "@basics/spacings";
import { typography, customTypography } from "@basics/typography";
import { Spinner } from "@components/Spinner/Spinner";
import { Button } from "@components/Button/Button";
import { RenderProperty } from "@/src/views/RenderProperty";
import { AdaptiveImageComponent } from "@components/AdaptiveImageComponent/AdaptiveImageComponent";
import { LocalizedLabel, localizedLabelString, LocalizationContext, } from "@hooks/LocalizationContext";
import { getNewsWallData, FiltersElement, } from "./NewsWallPage";
import { ContactExpert } from "@components/ContactExpert/ContactExpert";
import { useDebounce } from "@hooks/useDebounce";
import { getCurrentLocale } from "@basics/locales";
import { fonts } from "@basics/fonts";
import cn from "classnames";
import { DynamicDownloadBlock } from "@components/Block/DynamicDownloadBlock";
import { brandColors } from "@basics/brand-colors";
var PAGE_SIZE = 30;
var useStyles = createUseStyles({
    newsWall: __assign(__assign({ paddingTop: rem(spacings.m), paddingBottom: rem(spacings.l) }, onBreakpoint("sm", {
        paddingTop: rem(spacings.l),
        paddingBottom: rem(spacings.xl),
    })), onBreakpoint("md", {
        paddingTop: rem(spacings.xl),
        paddingBottom: rem(spacings.xxl),
    })),
    newsWallNoPaddingTop: __assign(__assign({ paddingTop: 0 }, onBreakpoint("sm", {
        paddingTop: 0,
    })), onBreakpoint("md", {
        paddingTop: 0,
    })),
    additionalTopContent: __assign(__assign({ marginTop: rem(spacings.m) }, onBreakpoint("sm", {
        marginTop: rem(spacings.l),
    })), onBreakpoint("md", {
        marginTop: rem(spacings.xl),
    })),
    headline: typography.h1,
    moreButton: {
        display: "flex",
        justifyContent: "center",
        paddingTop: rem(spacings.m),
    },
    noResults: __assign(__assign({}, typography.textDefault), { textAlign: "center" }),
});
export function PressSectionPage(props) {
    var _a, _b, _c, _d, _e;
    var initialQueryParametrs = new URLSearchParams(window.location.search);
    var dataFacets = (props.data.filters.expandedValue ||
        []);
    var _f = useContext(GlobalContentStoreContext), getStartPageData = _f.getStartPageData, getImmediateStartPageData = _f.getImmediateStartPageData, getWebsiteData = _f.getWebsiteData;
    var websiteData = getWebsiteData();
    var styles = useStyles();
    var localizationCtx = useContext(LocalizationContext);
    var _g = useState(getImmediateStartPageData()), startPageData = _g[0], setStartPageData = _g[1];
    var _h = useState(null), newsWallData = _h[0], setNewsWallData = _h[1];
    var _j = useState(false), isLoading = _j[0], setLoading = _j[1];
    var COMPANY_OPTIONS = [
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "HdiGlobal",
                localizationCtx: localizationCtx,
            }),
            value: "hdi-global",
        },
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "HdiSpecialty",
                localizationCtx: localizationCtx,
            }),
            value: "hdi-specialty",
        },
        {
            label: localizedLabelString({
                section: "JobListing",
                label: "HdiInsurance",
                localizationCtx: localizationCtx,
            }),
            value: "hdi-insurance",
        },
    ];
    var _k = useState(initialQueryParametrs.has("facet")
        ? Number(initialQueryParametrs.get("facet") || "")
        : null), activeFacet = _k[0], setActiveFacet = _k[1];
    var _l = useState(initialQueryParametrs.has("company")
        ? decodeURI(initialQueryParametrs.get("company") || "")
        : null), company = _l[0], setCompany = _l[1];
    var _m = useState(getCurrentLocale(websiteData, initialQueryParametrs)), localeSelection = _m[0], setLocaleSelection = _m[1];
    var _o = useState(0), page = _o[0], setPage = _o[1];
    var debouncePromise = useDebounce().debouncePromise;
    function loadItems(requestedPage) {
        var apiPage = requestedPage || page + 1;
        setLoading(true);
        return debouncePromise(function () {
            return getNewsWallData(apiPage, props.data.contentLink, localeSelection, activeFacet ? [activeFacet] : dataFacets.map(function (f) { return f.contentLink.id; }), [], false, false, company ? [company] : []).then(function (response) {
                setPage(apiPage);
                setLoading(false);
                return response;
            });
        });
    }
    useEffect(function () {
        !startPageData &&
            getStartPageData().then(function (response) {
                setStartPageData(response);
            });
    }, []);
    useEffect(function () {
        var queryParameters = new URLSearchParams();
        activeFacet &&
            queryParameters.set("category", encodeURI(String(activeFacet)));
        company && queryParameters.set("company", company);
        localeSelection && queryParameters.set("locale", localeSelection);
        window.history.replaceState({}, "", "".concat(window.location.pathname, "?").concat(queryParameters));
        setNewsWallData(null);
        loadItems(1).then(function (response) { return response && setNewsWallData(response); });
    }, [startPageData, activeFacet, company, localeSelection]);
    if (!startPageData)
        return null;
    var cards = (newsWallData && newsWallData.Hits) || [];
    var contactExpertData = ((_a = props.data.contactsUseDefaultContent) === null || _a === void 0 ? void 0 : _a.value)
        ? startPageData
        : props.data;
    var isAdditionalTopContent = !!((_b = props.data.additionalTopContent) === null || _b === void 0 ? void 0 : _b.expandedValue);
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, __assign({}, startPageData)),
        React.createElement("main", { "data-testid": "PressSectionPage" },
            isAdditionalTopContent && (React.createElement(RenderProperty, { value: props.data.additionalTopContent, className: styles.additionalTopContent })),
            React.createElement(Container, { className: cn(styles.newsWall, isAdditionalTopContent && styles.newsWallNoPaddingTop) },
                React.createElement(Row, { style: { width: "100%" } },
                    React.createElement(Col, { sm: 7, md: 8 },
                        React.createElement("h1", { className: styles.headline },
                            React.createElement(RenderProperty, { value: props.data.metaTitle, fallbackValue: props.data.name })))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 4, sm: 4, md: 3 },
                        React.createElement("aside", null,
                            React.createElement(FiltersElement, { headline: localizedLabelString({
                                    section: "NewsWallPage",
                                    label: "Filters",
                                    localizationCtx: localizationCtx,
                                }), defaultFilter: localizedLabelString({
                                    section: "NewsWallPage",
                                    label: "AllItems",
                                    localizationCtx: localizationCtx,
                                }), filters: dataFacets, activeFilter: activeFacet, setActiveFilter: setActiveFacet, companies: COMPANY_OPTIONS, activeCompany: company, setActiveCompany: setCompany, localeSelection: localeSelection, setLocaleSelection: setLocaleSelection, localeOptions: props.data.existingLanguages || [], teaserContent: props.data.teaserContent, enableLocalFilter: (_c = props.data.enableLocalFilter) === null || _c === void 0 ? void 0 : _c.value, enableCompanyFilter: (_d = props.data.enableCompanyFilter) === null || _d === void 0 ? void 0 : _d.value }))),
                    newsWallData ? (cards.length > 0 ? (React.createElement(Col, { xs: 4, sm: 8, md: 9 }, cards.map(function (card, idx) {
                        return card.Type === "Article" && React.createElement(Card, __assign({}, card, { key: idx }));
                    }))) : (React.createElement(Col, { xs: 4, sm: 12, md: 9, className: styles.noResults },
                        React.createElement(LocalizedLabel, { section: "NewsWallPage", label: "NoResultsFound" })))) : (React.createElement(Col, { xs: 4, sm: 12, md: 9 },
                        React.createElement(Spinner, null)))),
                newsWallData && newsWallData.TotalCount > page * PAGE_SIZE && (React.createElement(Row, null,
                    React.createElement(Col, { className: styles.moreButton }, isLoading ? (React.createElement(Spinner, null)) : (React.createElement(Button, { type: "secondary", onClick: function () {
                            loadItems().then(function (response) {
                                return response &&
                                    setNewsWallData(function (s) {
                                        return s
                                            ? __assign(__assign({}, s), { Hits: __spreadArray(__spreadArray([], s.Hits, true), response.Hits, true) }) : response;
                                    });
                            });
                        } },
                        React.createElement(LocalizedLabel, { section: "NewsWallPage", label: "MoreInsights" }))))))),
            React.createElement(RenderProperty, { value: props.data.additionalContent }),
            React.createElement(DynamicDownloadBlock, { downloadCategoriesFilter: props.data.downloadCategoriesFilter, downloadsManual: props.data.downloadsManual }),
            ((_e = props.data.contactCta) === null || _e === void 0 ? void 0 : _e.value) === "enabled" && (React.createElement(ContactExpert, __assign({}, contactExpertData)))),
        React.createElement(Footer, __assign({}, startPageData))));
}
var useCardStyles = createUseStyles({
    newsItem: {
        display: "flex",
        padding: "".concat(spacings.sam, " 0"),
        borderTop: "2px solid ".concat(brandColors.lightGrey),
        "&:last-child": {
            borderBottom: "2px solid ".concat(brandColors.lightGrey),
        },
    },
    articleImageWrapper: {
        overflow: "hidden",
        display: "block",
    },
    articleImage: {
        transform: "none",
        transition: "transform 0.3s ease",
        "&:hover": {
            transform: "scale(1.03)",
            transition: "transform 0.3s ease",
        },
        "& img": __assign(__assign({}, onBefore("sm", {
            paddingBottom: rem(spacings.sam),
        })), { height: "auto" }),
    },
    h4: customTypography(typography.h4, { marginBottom: 0 }, { marginBottom: 0 }),
    caption: typography.caption,
    textDefault: __assign(__assign({ paddingTop: rem(spacings.s) }, typography.textDefault), fonts.book),
    fullWidth: {
        width: "100%",
    },
});
function Card(props) {
    var styles = useCardStyles();
    var hasImage = props.ImageUrlLarge || props.ImageUrlMedium || props.ImageUrlSmall;
    var captionText = [props.Date, props.Location, props.Author]
        .filter(function (text) { return Boolean(text); })
        .join(" | ");
    return (React.createElement(Container, { className: styles.newsItem, dataTestid: "PressSectionCard" },
        React.createElement(Row, null,
            hasImage && (React.createElement(Col, { sm: 4, md: 3 },
                React.createElement("a", { href: props.Url, className: styles.articleImageWrapper },
                    React.createElement(AdaptiveImageComponent, { large: props.ImageUrlLarge, medium: props.ImageUrlMedium, small: props.ImageUrlSmall, alt: props.ImageAlt, className: styles.articleImage })))),
            React.createElement(Col, { sm: hasImage ? 8 : 12, md: hasImage ? 9 : 12 },
                React.createElement("span", { className: styles.caption }, captionText),
                React.createElement("h4", { className: styles.h4 }, props.Title),
                React.createElement("p", { className: styles.textDefault }, props.Description),
                React.createElement(Button, { type: "link", href: props.Url },
                    React.createElement(LocalizedLabel, { section: "Global", label: "ReadMore" }))))));
}
