var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from "react";
import { createUseStyles } from "react-jss";
import cn from "classnames";
import { customTypography, typography } from "@basics/typography";
import { spacings } from "@basics/spacings";
import { onBefore, onBreakpoint, rem } from "@basics/layout";
import { FAIcon, FAIconStyles } from "@components/FAIcon/FAIcon";
import { AnimatedRevealBlock } from "@components/AnimatedReveal/AnimatedReveal";
import { CountUpAnimation } from "@components/CountUpAnimation/CountUpAnimation";
import { GlobalContentStoreContext } from "@hooks/GlobalContentStore";
import { brandColors } from "@basics/brand-colors";
var useLayoutOneStyles = createUseStyles({
    factsAndFiguresBlock: __assign(__assign(__assign(__assign({ position: "relative" }, onBreakpoint("md", {
        gridArea: "1 / 6 / span 2 / span 2",
        height: "min(342px, calc(3 / 12 / 100vw - 34px))",
    })), onBefore("md", {
        flexBasis: "calc(50% - 12px)",
        marginBottom: rem(spacings.sam),
    })), onBefore("sm", {
        flexBasis: "100%",
    })), { "&:nth-child(1)": __assign(__assign({}, onBreakpoint("sm", {
            marginRight: rem(spacings.sam),
        })), onBreakpoint("md", {
            gridArea: "1 / 1 / span 2 / span 3",
            marginRight: 0,
        })), "&:nth-child(2)": __assign({}, onBreakpoint("md", {
            gridArea: "1 / 4 / span 2 / span 3",
        })), "&:nth-child(3)": __assign(__assign({}, onBreakpoint("sm", {
            marginRight: rem(spacings.sam),
        })), onBreakpoint("md", {
            gridArea: "1 / 7 / span 2 / span 3",
            marginRight: 0,
        })), "&:nth-child(4)": __assign({}, onBreakpoint("md", {
            gridArea: "1 / 10 / span 2 / span 3",
        })) }),
    withImage: {
        position: "relative",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
        "& $content": {
            position: "absolute",
            top: 0,
        },
    },
    backgroundCover: {
        content: "''",
        display: "block",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, .3)",
    },
    lightGreen: {
        backgroundColor: brandColors.universalGreen,
    },
    darkGreen: {
        backgroundColor: brandColors.green,
    },
    lightGreenAlt: {
        backgroundColor: brandColors.universalGreen40,
        "& $content > *": {
            color: brandColors.darkGrey,
        },
    },
    darkGreenAlt: {
        backgroundColor: brandColors.webGreen50,
        "& $content > *": {
            color: brandColors.darkGrey,
        },
    },
    red: {
        backgroundColor: brandColors.darkRed,
    },
    orange: {
        backgroundColor: brandColors.ochre,
    },
    blue: {
        backgroundColor: brandColors.lightBlue,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        padding: rem(spacings.sam),
        height: "100%",
        "& > $icon:nth-child(2)": {
            marginBottom: rem(spacings.s),
        },
        "& > *:last-child": {
            marginTop: "auto",
            marginBottom: 0,
        },
    },
    headline: customTypography(__assign(__assign({}, typography.h4), { fontSize: "min(1.9rem, 6vw)", color: brandColors.white }), {}, { marginBottom: 0 }, { fontSize: "min(28px, 1.7vw)" }),
    number: customTypography(__assign(__assign({}, typography.h1), { color: brandColors.white }), { fontSize: "min(6rem, 15vw)" }, { marginBottom: 0, fontSize: "4.5rem" }, { marginBottom: 0, fontSize: "min(76px, 5.2vw)", lineHeight: "1.25" }),
    text: customTypography(__assign(__assign({}, typography.textDefault), { fontSize: "1.25rem", color: brandColors.white, marginBottom: 0 }), {}, {}, { fontSize: "min(20px, 1.4vw)" }),
    icon: FAIconStyles(__assign(__assign({ color: brandColors.white, width: 40, height: 40 }, onBreakpoint("sm", {
        width: 56,
        height: 56,
    })), onBreakpoint("md", {
        width: "min(80px, 5.7vw)",
        height: "min(80px, 5.7vw)",
    }))),
    vertical: {},
    threeElements: {},
});
var useLayoutTwoStyles = createUseStyles({
    factsAndFiguresBlock: __assign(__assign(__assign({ position: "relative" }, onBefore("md", {
        flexBasis: "calc(50% - 12px)",
        marginBottom: rem(spacings.sam),
    })), onBefore("sm", {
        flexBasis: "100%",
    })), { "&:nth-child(1)": __assign(__assign({ "& $number": __assign(__assign({ fontSize: "min(6rem, 15vw)", lineHeight: 1.25 }, onBreakpoint("sm", {
                fontSize: "4.5rem",
            })), onBreakpoint("md", {
                fontSize: "min(144px, 9vw)",
            })) }, onBreakpoint("sm", {
            marginRight: rem(spacings.sam),
        })), onBreakpoint("md", {
            gridArea: "1 / 1 / span 2 / span 5",
            height: "min(342px, calc(3 / 12 * 100vw - 34px))",
            marginRight: 0,
        })), "&:nth-child(2)": __assign({}, onBreakpoint("md", {
            gridArea: "1 / 6 / span 2 / span 3",
            height: "min(342px, calc(3 / 12 * 100vw - 34px))",
        })), "&:nth-child(3)": __assign(__assign({ "& $content": {
                alignItems: "center",
                flexDirection: "row",
                "&$vertical": {
                    flexDirection: "column",
                    alignItems: "flex-start",
                    "& > *:first-child": {
                        marginBottom: rem(spacings.xs),
                    },
                    "& > *:last-child": {
                        marginTop: "auto",
                    },
                },
                "&$threeElements": {
                    position: "absolute",
                    top: 0,
                    "& > $number:nth-child(1), & > $number:nth-child(2)": {
                        position: "relative",
                        top: -8,
                    },
                    "& > $icon:nth-child(1), & > $icon:nth-child(2)": {
                        position: "relative",
                        top: -8,
                    },
                    "& > $headline:nth-child(2), & > $headline:nth-child(3)": __assign(__assign({ position: "absolute", right: 24, bottom: 20 }, onBefore("md", {
                        bottom: "12.5vw",
                    })), onBefore("sm", {
                        bottom: "26vw",
                    })),
                },
                "& > *:first-child": {
                    marginRight: rem(spacings.sam),
                },
                "& > *:last-child": {
                    marginTop: "unset",
                },
                "& $headline, & $number, & $icon": {
                    marginBottom: 0,
                },
                "& $number": {
                    flexShrink: 0,
                },
            } }, onBreakpoint("sm", {
            marginRight: rem(spacings.sam),
        })), onBreakpoint("md", {
            gridArea: "1 / 9 / span 1 / span 4",
            height: "min(159px, calc(1.5 / 12 * 100vw - 29px))",
            marginRight: 0,
        })), "&:nth-child(4)": __assign({ "& $content": {
                flexDirection: "row",
                alignItems: "center",
                "&$vertical": {
                    flexDirection: "column",
                    alignItems: "flex-start",
                    "& > *:first-child": {
                        marginBottom: rem(spacings.xs),
                    },
                    "& > *:last-child": {
                        marginTop: "auto",
                    },
                },
                "&$threeElements": {
                    position: "absolute",
                    top: 0,
                    "& > $number:nth-child(1), & > $number:nth-child(2)": {
                        position: "relative",
                        top: -8,
                    },
                    "& > $icon:nth-child(1), & > $icon:nth-child(2)": {
                        position: "relative",
                        top: -8,
                    },
                    "& > $headline:nth-child(2), & > $headline:nth-child(3)": __assign(__assign({ position: "absolute", right: 24, bottom: 20 }, onBefore("md", {
                        bottom: "12.5vw",
                    })), onBefore("sm", {
                        bottom: "26vw",
                    })),
                },
                "& > *:first-child": {
                    marginRight: rem(spacings.sam),
                },
                "& > *:last-child": {
                    marginTop: "unset",
                },
                "& $headline, & $number, & $icon": {
                    marginBottom: 0,
                },
                "& $number": {
                    flexShrink: 0,
                },
            } }, onBreakpoint("md", {
            gridArea: "2 / 9 / span 1 / span 4",
            height: "min(159px, calc(1.5 / 12 * 100vw - 29px))",
        })) }),
    withImage: {
        position: "relative",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
        "& $content": {
            position: "absolute",
            top: 0,
        },
    },
    backgroundCover: {
        content: "''",
        display: "block",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, .35)",
    },
    lightGreen: {
        backgroundColor: brandColors.universalGreen,
    },
    darkGreen: {
        backgroundColor: brandColors.green,
    },
    lightGreenAlt: {
        backgroundColor: brandColors.universalGreen40,
        "& $content > *": {
            color: brandColors.darkGrey,
        },
    },
    darkGreenAlt: {
        backgroundColor: brandColors.webGreen50,
        "& $content > *": {
            color: brandColors.darkGrey,
        },
    },
    red: {
        backgroundColor: brandColors.darkRed,
    },
    orange: {
        backgroundColor: brandColors.ochre,
    },
    blue: {
        backgroundColor: brandColors.lightBlue,
    },
    content: {
        display: "flex",
        flexDirection: "column",
        padding: rem(spacings.sam),
        height: "100%",
        "& > $icon:nth-child(2)": {
            marginBottom: rem(spacings.s),
        },
        "& > *:last-child": {
            marginTop: "auto",
            marginBottom: 0,
        },
    },
    headline: customTypography(__assign(__assign({}, typography.h4), { fontSize: "min(1.9rem, 6vw)", color: brandColors.white }), {}, { marginBottom: 0 }, { fontSize: "min(28px, 1.7vw)" }),
    number: customTypography(__assign(__assign({}, typography.h1), { color: brandColors.white }), { fontSize: "min(6rem, 15vw)" }, { marginBottom: 0, fontSize: "4.5rem" }, { marginBottom: 0, fontSize: "min(76px, 5.2vw)", lineHeight: "1.30" }),
    text: customTypography(__assign(__assign({}, typography.textDefault), { fontSize: "1.25.rem", color: brandColors.white, marginBottom: 0 }), {}, {}, { fontSize: "min(20px, 1.4vw)" }),
    icon: FAIconStyles(__assign(__assign({ color: brandColors.white, width: 40, height: 40 }, onBreakpoint("sm", {
        width: 56,
        height: 56,
    })), onBreakpoint("md", {
        width: "min(80px, 5.7vw)",
        height: "min(80px, 5.7vw)",
    }))),
    vertical: {},
    threeElements: {},
});
var useLayoutThreeStyles = createUseStyles({
    factsAndFiguresBlock: __assign(__assign(__assign({ position: "relative" }, onBefore("md", {
        flexBasis: "calc(50% - 12px)",
        marginBottom: rem(spacings.sam),
    })), onBefore("sm", {
        flexBasis: "100%",
    })), { "&:nth-child(1)": __assign(__assign({}, onBreakpoint("sm", {
            marginRight: rem(spacings.sam),
        })), onBreakpoint("md", {
            gridArea: "1 / 1 / span 2 / span 3",
            height: "min(342px, calc(3 / 12 * 100vw - 34px))",
            marginRight: 0,
        })), "&:nth-child(2)": __assign({ "& $content": {
                alignItems: "center",
                flexDirection: "row",
                "&$vertical": {
                    flexDirection: "column",
                    alignItems: "flex-start",
                    "& > *:first-child": {
                        marginBottom: rem(spacings.xs),
                    },
                    "& > *:last-child": {
                        marginTop: "auto",
                    },
                },
                "&$threeElements": {
                    position: "absolute",
                    top: 0,
                    "& > $number:nth-child(1), & > $number:nth-child(2)": {
                        position: "relative",
                        top: -8,
                    },
                    "& > $icon:nth-child(1), & > $icon:nth-child(2)": {
                        position: "relative",
                        top: -8,
                    },
                    "& > $headline:nth-child(2), & > $headline:nth-child(3)": __assign(__assign({ position: "absolute", right: 24, bottom: 20 }, onBefore("md", {
                        bottom: "12.5vw",
                    })), onBefore("sm", {
                        bottom: "26vw",
                    })),
                },
                "& > *:first-child": {
                    marginRight: rem(spacings.sam),
                },
                "& > *:last-child": {
                    marginTop: "unset",
                },
                "& $headline, & $number, & $icon": {
                    marginBottom: 0,
                },
                "& $number": {
                    flexShrink: 0,
                },
            } }, onBreakpoint("md", {
            gridArea: "1 / 4 / span 1 / span 4",
            height: "min(159px, calc(1.5 / 12 * 100vw - 29px))",
        })), "&:nth-child(3)": __assign(__assign({ "& $content": {
                flexDirection: "row",
                alignItems: "center",
                "&$vertical": {
                    flexDirection: "column",
                    alignItems: "flex-start",
                    "& > *:first-child": {
                        marginBottom: rem(spacings.xs),
                    },
                    "& > *:last-child": {
                        marginTop: "auto",
                    },
                },
                "&$threeElements": {
                    position: "absolute",
                    top: 0,
                    "& > $number:nth-child(1), & > $number:nth-child(2)": {
                        position: "relative",
                        top: -8,
                    },
                    "& > $icon:nth-child(1), & > $icon:nth-child(2)": {
                        position: "relative",
                        top: -8,
                    },
                    "& > $headline:nth-child(2), & > $headline:nth-child(3)": __assign(__assign({ position: "absolute", right: 24, bottom: 20 }, onBefore("md", {
                        bottom: "12.5vw",
                    })), onBefore("sm", {
                        bottom: "26vw",
                    })),
                },
                "& > *:first-child": {
                    marginRight: rem(spacings.sam),
                },
                "& > *:last-child": {
                    marginTop: "unset",
                },
                "& $headline, & $number, & $icon": {
                    marginBottom: 0,
                },
                "& $number": {
                    flexShrink: 0,
                },
            } }, onBreakpoint("sm", {
            marginRight: rem(spacings.sam),
        })), onBreakpoint("md", {
            gridArea: "2 / 4 / span 1 / span 4",
            height: "min(159px, calc(1.5 / 12 * 100vw - 29px))",
            marginRight: 0,
        })), "&:nth-child(4)": __assign({ "& $number": __assign(__assign({ fontSize: "min(6rem, 15vw)", lineHeight: 1.25 }, onBreakpoint("sm", {
                fontSize: "4.5rem",
            })), onBreakpoint("md", {
                fontSize: "min(144px, 9vw)",
            })) }, onBreakpoint("md", {
            gridArea: "1 / 8 / span 2 / span 5",
            height: "min(342px, calc(3 / 12 * 100vw - 34px))",
        })) }),
    withImage: {
        position: "relative",
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
        "& $content": {
            position: "absolute",
            top: 0,
        },
    },
    backgroundCover: {
        content: "''",
        display: "block",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, .3)",
    },
    lightGreen: {
        backgroundColor: brandColors.universalGreen,
    },
    darkGreen: {
        backgroundColor: brandColors.green,
    },
    lightGreenAlt: {
        backgroundColor: brandColors.universalGreen40,
        "& $content > *": {
            color: brandColors.darkGrey,
        },
    },
    darkGreenAlt: {
        backgroundColor: brandColors.webGreen50,
        "& $content > *": {
            color: brandColors.darkGrey,
        },
    },
    red: {
        backgroundColor: brandColors.darkRed,
    },
    orange: {
        backgroundColor: brandColors.ochre,
    },
    blue: { backgroundColor: brandColors.lightBlue },
    content: {
        display: "flex",
        flexDirection: "column",
        padding: rem(spacings.sam),
        height: "100%",
        "& > $icon:nth-child(2)": {
            marginBottom: rem(spacings.s),
        },
        "& > *:last-child": {
            marginTop: "auto",
            marginBottom: 0,
        },
    },
    headline: customTypography(__assign(__assign({}, typography.h4), { fontSize: "min(1.9rem, 6vw)", color: brandColors.white }), {}, { marginBottom: 0 }, { fontSize: "min(28px, 1.7vw)" }),
    number: customTypography(__assign(__assign({}, typography.h1), { color: brandColors.white }), { fontSize: "min(6rem, 15vw)" }, { marginBottom: 0, fontSize: "4.5rem" }, { marginBottom: 0, fontSize: "min(76px, 5.2vw)" }),
    text: customTypography(__assign(__assign({}, typography.textDefault), { fontSize: "1.25.rem", color: brandColors.white, marginBottom: 0 }), {}, {}, { fontSize: "min(20px, 1.4vw)", lineHeight: "1.30" }),
    icon: FAIconStyles(__assign(__assign({ color: brandColors.white, width: 40, height: 40 }, onBreakpoint("sm", {
        width: 56,
        height: 56,
    })), onBreakpoint("md", {
        width: "min(80px, 5.7vw)",
        height: "min(80px, 5.7vw)",
    }))),
    vertical: {},
    threeElements: {},
});
export function FactsAndFiguresLayout(props) {
    var blocks = props.blocks, layout = props.layout;
    var layout1 = useLayoutOneStyles();
    var layout2 = useLayoutTwoStyles();
    var layout3 = useLayoutThreeStyles();
    var styles = layout === "1" ? layout1 : layout === "2" ? layout2 : layout3;
    var getWebsiteData = useContext(GlobalContentStoreContext).getWebsiteData;
    var websiteData = getWebsiteData();
    var currentLanguage = websiteData === null || websiteData === void 0 ? void 0 : websiteData.currentLanguage;
    return (React.createElement(React.Fragment, null, blocks.map(function (block, idx) { return (React.createElement(AnimatedRevealBlock, { className: cn(styles.factsAndFiguresBlock, block.schema && styles[block.schema], block.backgroundImage && styles.withImage), key: idx, revealDelay: idx * 100 },
        block.backgroundImage && React.createElement("img", { src: block.backgroundImage }),
        block.backgroundImage && React.createElement("div", { className: styles.backgroundCover }),
        React.createElement("div", { className: cn(styles.content, block.elements.some(function (e) { return e.type === "text"; }) &&
                block.elements.some(function (e) { return e.type === "headline"; })
                ? styles.vertical
                : undefined, (layout2 &&
                block.elements.length > 2 &&
                (blocks[2] === block || blocks[3] === block)) ||
                (layout3 &&
                    block.elements.length > 2 &&
                    (blocks[1] === block || blocks[2] === block))
                ? styles.threeElements
                : "") }, block.elements.map(function (element, idx) { return (React.createElement(React.Fragment, { key: idx },
            element.type === "headline" && (React.createElement("h4", { className: styles.headline }, element.value)),
            element.type === "number" && (React.createElement("span", { className: styles.number },
                element.prefix,
                React.createElement(CountUpAnimation, { decimalSeparator: element.decimalSeparator, thousandsSeparator: element.thousandsSeparator, currentLanguage: (currentLanguage === null || currentLanguage === void 0 ? void 0 : currentLanguage.name) || "" }, element.value),
                element.suffix)),
            element.type === "text" && (React.createElement("p", { className: styles.text }, element.value)),
            element.type === "icon" && (React.createElement(FAIcon, { icon: element.value || "", className: styles.icon })))); })))); })));
}
