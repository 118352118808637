var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createUseStyles } from "react-jss";
import { onBefore, onBreakpoint, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { FAIconStyles } from "@components/FAIcon/FAIcon";
import { brandColors } from "@basics/brand-colors";
export var contactCardExpertStyles = createUseStyles({
    card: __assign(__assign(__assign({ display: "flex", flexDirection: "column", backgroundColor: brandColors.white, position: "relative", marginLeft: function (_a) {
            var isSingleElement = _a.isSingleElement;
            return isSingleElement ? rem(spacings.s) : undefined;
        }, textAlign: "center", height: "100%", borderRadius: 10 }, onBefore("sm", {
        maxWidth: 346,
        marginLeft: "auto !important",
        marginRight: "auto",
    })), { "&:before": {
            display: function (_a) {
                var isPhoto = _a.isPhoto;
                return (isPhoto ? "block" : "none");
            },
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: 240,
            backgroundColor: brandColors.mediumGrey,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
        } }), onBefore("md", {
        "&:before": {
            display: function () { return "none"; },
        },
    })),
    singleCard: __assign(__assign({}, onBefore("sm", {
        paddingBottom: rem(spacings.m),
    })), { "& $photo": __assign({}, onBefore("sm", {
            maxWidth: 180,
            margin: "".concat(rem(spacings.m), " auto"),
        })), "& $bioSection": __assign({ paddingTop: function (_a) {
                var isPhoto = _a.isPhoto;
                return (isPhoto ? undefined : rem(spacings.m));
            }, padding: "0 ".concat(rem(spacings.m), " 0 ").concat(rem(spacings.m)) }, onBreakpoint("sm", {
            padding: "0 ".concat(rem(spacings.s), " ").concat(rem(spacings.m)),
        })) }),
    landscaped: {
        "&:before": {
            display: function () { return "none"; },
        },
        "& $photo": __assign(__assign({ margin: 0, marginBottom: rem(spacings.xl.add(spacings.xs)), maxWidth: "unset" }, onBefore("md", {
            marginBottom: rem(spacings.m),
        })), { "& img": {
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
            } }),
    },
    onlyTablet: {},
    cardMobile: {
        height: "auto",
        marginBottom: rem(spacings.sam),
        flexDirection: "row",
        textAlign: "left",
        flexWrap: "wrap",
        padding: "".concat(rem(spacings.s), " ").concat(rem(spacings.s), " ").concat(rem(spacings.m)),
        maxWidth: "unset",
        "& > div:first-child": {
            flexShrink: 0,
        },
        "& $photo": __assign({ maxWidth: 120, marginRight: rem(spacings.s) }, onBreakpoint("sm", {
            maxWidth: 156,
            margin: "".concat(rem(spacings.m)),
        })),
        "& $bioSection": __assign({ width: "100%", height: "auto", alignItems: "unset", paddingTop: 0 }, onBreakpoint("sm", {
            padding: "".concat(rem(spacings.m), " ").concat(rem(spacings.m), " ").concat(rem(spacings.m), " 0"),
        })),
        "& $ctaButtons": {
            marginTop: "unset",
            "& button": {
                marginTop: rem(spacings.sam),
            },
        },
        "&$onlyTablet": {
            padding: 0,
            flexWrap: "unset",
            "& $bioSection": {
                paddingLeft: function (_a) {
                    var isPhoto = _a.isPhoto;
                    return (isPhoto ? undefined : rem(spacings.m));
                },
            },
        },
    },
    photoWrapper: __assign({}, onBreakpoint("md", {
        padding: "0 ".concat(rem(spacings.m)),
    })),
    photo: __assign(__assign(__assign({ position: "relative" }, onBreakpoint("sm", {
        margin: "".concat(rem(spacings.m)),
    })), onBreakpoint("md", {
        margin: "".concat(rem(spacings.m), " auto ").concat(rem(spacings.sam)),
        maxWidth: 240,
    })), { "& img": __assign({ borderRadius: 10, objectFit: "cover" }, onBreakpoint("md", {
            height: 240,
        })) }),
    bioSection: __assign(__assign({ display: "flex", flexDirection: "column", height: "100%", alignItems: "center", flexBasis: function (_a) {
            var isPhoto = _a.isPhoto;
            return (isPhoto ? "calc(100% - 136px)" : undefined);
        }, paddingTop: function (_a) {
            var isPhoto = _a.isPhoto;
            return (isPhoto ? undefined : rem(spacings.m));
        } }, onBreakpoint("sm", {
        padding: "0 ".concat(rem(spacings.s), " ").concat(rem(spacings.m), " ").concat(rem(spacings.s), " "),
        flexBasis: "unset",
    })), onBreakpoint("md", {
        padding: "0 ".concat(rem(spacings.m), " ").concat(rem(spacings.m)),
    })),
    name: customTypography(__assign({}, typography.h4), {
        marginBottom: rem(spacings.s),
    }, {
        marginBottom: rem(spacings.s),
    }),
    role: __assign({}, customTypography(__assign(__assign({}, typography.textDefault), { color: brandColors.grey }), {}, {
        marginBottom: 0,
    })),
    jobTitle: __assign({}, customTypography(__assign({}, typography.textDefault), {}, {
        marginBottom: rem(spacings.xxs),
    })),
    subtitle: __assign({}, customTypography(__assign({}, typography.textDefault), {}, {
        marginBottom: 0,
    })),
    ctaButtons: __assign(__assign({ marginTop: "auto" }, onBefore("sm", {
        display: "none",
    })), { "& button": {
            marginTop: rem(spacings.m),
        } }),
    iconsMobile: __assign(__assign({ display: "flex", flexDirection: "column", alignItems: function (_a) {
            var isSingleElement = _a.isSingleElement;
            return isSingleElement ? "center" : "flex-end";
        } }, onBreakpoint("sm", {
        display: "none",
    })), { "& div": {
            display: "flex",
        }, "& a": {
            backgroundColor: brandColors.lightGrey,
            marginTop: rem(spacings.s),
            padding: "12px 12px 9px 12px",
            borderRadius: "50%",
            marginRight: rem(spacings.xs),
            "&:last-child": {
                marginRight: 0,
            },
        }, "& button": {
            marginTop: rem(spacings.sam),
        } }),
    buttonMobile: __assign({ flexBasis: "100%", textAlign: function (_a) {
            var isSingleElement = _a.isSingleElement;
            return (isSingleElement ? "center" : "right");
        }, marginTop: rem(spacings.sam) }, onBreakpoint("sm", {
        display: "none",
    })),
    icon: __assign({}, FAIconStyles({
        color: brandColors.darkGrey,
        width: 24,
        height: 24,
        cursor: "pointer",
    })),
    accessibilityText: typography.visuallyHidden,
});
