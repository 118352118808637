var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { Container, onBreakpoint } from "@basics/layout";
import { pseudoSelectorShadow } from "@basics/mixins";
import { LanguagePicker } from "@components/LanguagePicker/LanguagePicker";
import { MonsterNavigation } from "./MonsterNavigation/MonsterNavigation";
import Search from "./Search/Search";
import { brandColors } from "@basics/brand-colors";
import { headerMixins, headerTop, navLeft, navRight, contactUsButtonWrapper, contactUsButtonWrapperShow, headerBottomContent, iconsWrapper, headerTopContent, } from "./commonStyles";
import { MenuLabelWithIcon } from "./MenuLabelWithIcon";
import { SearchIcon } from "./SearchIcon";
import { UserLoginLinkWithIcon } from "./UserLoginLinkWithIcon";
var HEADER_TOP_CONTENT_HEIGHT = 40;
export var useStyles = createUseStyles({
    header: function (_a) {
        var liftHeaderUpByPx = _a.liftHeaderUpByPx;
        return ({
            position: "sticky",
            top: 0,
            zIndex: 99,
            transition: "transform 0.3s ease",
            transform: "translateY(-".concat(liftHeaderUpByPx, "px)"),
        });
    },
    headerBottom: __assign(__assign({ display: "flex", alignItems: "center", height: 64, backgroundColor: brandColors.white, position: "relative", borderBottom: function (_a) {
            var isMenuOpened = _a.isMenuOpened, isSearchOpened = _a.isSearchOpened;
            return isMenuOpened || isSearchOpened ? "1px solid ".concat(brandColors.lightGrey) : 0;
        }, "&:after": __assign(__assign({}, pseudoSelectorShadow("0 4px 6px 0 rgba(0, 0, 0, 0.09)")), { height: 12, opacity: function (_a) {
                var isMenuOpened = _a.isMenuOpened, isSearchOpened = _a.isSearchOpened;
                return isMenuOpened || isSearchOpened ? 0 : 1;
            }, transition: "all 0.5s" }) }, onBreakpoint("sm", {
        borderBottom: function () { return 0; },
    })), onBreakpoint("md", {
        height: 80,
    })),
    logo: {
        display: "flex",
        marginRight: "auto",
        "& img": __assign(__assign({ width: 42, height: 17, objectFit: "contain" }, onBreakpoint("sm", {
            width: 60,
            height: 24,
        })), onBreakpoint("md", {
            width: 72,
            height: 29,
        })),
    },
    headerMixins: headerMixins,
    headerTop: headerTop,
    headerTopContent: __assign(__assign({}, headerTopContent), { height: HEADER_TOP_CONTENT_HEIGHT }),
    headerBottomContent: headerBottomContent,
    navLeft: navLeft,
    navRight: navRight,
    contactUsButtonWrapper: contactUsButtonWrapper,
    contactUsButtonWrapperShow: contactUsButtonWrapperShow,
    searchIcon: {
        color: brandColors.black,
    },
    iconsWrapper: iconsWrapper,
    userIcon: {
        color: brandColors.black,
    },
    mainMenu: {
        "& span": {
            color: brandColors.black,
        },
        "& div div": {
            background: brandColors.black,
        },
    },
});
export function Header(props) {
    var _a, _b, _c, _d, _e;
    var keyVisualRef = props.keyVisualRef, topBar = props.topBar, contactListRef = props.contactListRef, metaNavigationLeft = props.metaNavigationLeft, metaNavigationRight = props.metaNavigationRight, languagePicker = props.languagePicker, logoLink = props.logoLink, logo = props.logo, headerButtonPortalRef = props.headerButtonPortalRef, hideSearch = props.hideSearch, searchField = props.searchField, userLogin = props.userLogin, businessPortalLoginLink = props.businessPortalLoginLink, navigationMenu = props.navigationMenu, searchPage = props.searchPage, homepageSearch = props.homepageSearch;
    var _f = useState(0), liftHeaderUpByPx = _f[0], setLiftHeaderUpByPx = _f[1];
    var _g = useState(false), showCtaButton = _g[0], setShowCtaButton = _g[1];
    var menuToggler = useRef(null);
    var localeSuggestionWrapperRef = useRef(null);
    var searchRef = useRef(null);
    var keyVisualButtonsPosition = (keyVisualRef === null || keyVisualRef === void 0 ? void 0 : keyVisualRef.current) &&
        ((_a = keyVisualRef === null || keyVisualRef === void 0 ? void 0 : keyVisualRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect().top) + window.scrollY;
    var contactListPosition = (contactListRef === null || contactListRef === void 0 ? void 0 : contactListRef.current) &&
        ((_b = contactListRef === null || contactListRef === void 0 ? void 0 : contactListRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect().top) + window.scrollY;
    useScrollPosition(function (_a) {
        var _b, _c;
        var currPos = _a.currPos, prevPos = _a.prevPos;
        if (currPos.y === prevPos.y) {
            return;
        }
        var newVisibilityStatus = currPos.y < prevPos.y || currPos.y < 0 || prevPos.y < 0;
        var localeSuggestionWrapperHeight = ((_c = (_b = localeSuggestionWrapperRef === null || localeSuggestionWrapperRef === void 0 ? void 0 : localeSuggestionWrapperRef.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect()) === null || _c === void 0 ? void 0 : _c.height) ||
            0;
        setLiftHeaderUpByPx(newVisibilityStatus || !(topBar === null || topBar === void 0 ? void 0 : topBar.value)
            ? 0
            : localeSuggestionWrapperHeight + HEADER_TOP_CONTENT_HEIGHT);
        if (!keyVisualButtonsPosition)
            return;
        var shouldShowButton = currPos.y - keyVisualButtonsPosition > -64 &&
            (contactListPosition ? contactListPosition - currPos.y > 64 : true);
        setShowCtaButton(shouldShowButton);
    }, [liftHeaderUpByPx, showCtaButton], undefined, true, 300);
    var _h = useState({
        openedMenu: false,
        openedSearch: false,
    }), headerElementToggle = _h[0], setHeaderElementToggle = _h[1];
    var isMenuOpened = headerElementToggle.openedMenu;
    var isSearchOpened = headerElementToggle.openedSearch;
    function menuToggle() {
        setHeaderElementToggle(function (s) { return ({
            openedSearch: false,
            openedMenu: !s.openedMenu,
        }); });
    }
    function searchToggle() {
        setHeaderElementToggle(function (s) { return ({
            openedSearch: !s.openedSearch,
            openedMenu: false,
        }); });
    }
    function handleClickOutside(event) {
        if (menuToggler.current && menuToggler.current.contains(event.target)) {
            setHeaderElementToggle(function (s) { return ({
                openedMenu: s.openedMenu,
                openedSearch: false,
            }); });
        }
        else if (searchRef.current && !searchRef.current.contains(event.target)) {
            isSearchOpened &&
                setHeaderElementToggle(function (s) { return ({
                    openedMenu: s.openedMenu,
                    openedSearch: false,
                }); });
        }
    }
    useEffect(function () {
        isMenuOpened
            ? (document.body.style.position = "fixed")
            : (document.body.style.position = "static");
        document.addEventListener("click", handleClickOutside);
        return function () {
            document.removeEventListener("click", handleClickOutside);
        };
    });
    var styles = useStyles({
        liftHeaderUpByPx: liftHeaderUpByPx,
        isMenuOpened: isMenuOpened,
        isSearchOpened: isSearchOpened,
    });
    return (React.createElement("header", { className: cn(styles.header, styles.headerMixins), "data-testid": "header" },
        (topBar === null || topBar === void 0 ? void 0 : topBar.value) && (React.createElement("div", { className: styles.headerTop },
            React.createElement(Container, null,
                React.createElement("div", { className: styles.headerTopContent },
                    React.createElement("nav", { className: styles.navLeft },
                        React.createElement("ul", null, (_c = metaNavigationLeft.value) === null || _c === void 0 ? void 0 : _c.map(function (l, idx) { return (React.createElement("li", { key: idx },
                            React.createElement("a", { href: l.href, title: l.title, target: l.target }, l.text))); }))),
                    React.createElement("nav", { className: styles.navRight },
                        React.createElement("ul", null, (_d = metaNavigationRight.value) === null || _d === void 0 ? void 0 : _d.map(function (l, idx) { return (React.createElement("li", { key: idx },
                            React.createElement("a", { href: l.href, title: l.title, target: l.target }, l.text))); })),
                        languagePicker.value && React.createElement(LanguagePicker, null)))))),
        React.createElement("div", { className: styles.headerBottom },
            React.createElement(Container, null,
                React.createElement("div", { className: styles.headerBottomContent },
                    React.createElement("a", { href: (logoLink === null || logoLink === void 0 ? void 0 : logoLink.value) ? logoLink.value : undefined, className: styles.logo },
                        React.createElement("img", { src: ((_e = logo === null || logo === void 0 ? void 0 : logo.value) === null || _e === void 0 ? void 0 : _e.url) || undefined, alt: "logo" })),
                    React.createElement("div", { className: styles.iconsWrapper },
                        React.createElement("div", { ref: headerButtonPortalRef, className: cn(styles.contactUsButtonWrapper, showCtaButton && styles.contactUsButtonWrapperShow) }),
                        !hideSearch && (searchField === null || searchField === void 0 ? void 0 : searchField.value) && (React.createElement(SearchIcon, { isSearchOpened: isSearchOpened, isMenuOpened: isMenuOpened, searchToggle: searchToggle, className: styles.searchIcon })),
                        (userLogin === null || userLogin === void 0 ? void 0 : userLogin.value) && businessPortalLoginLink.value && (React.createElement(UserLoginLinkWithIcon, { businessPortalLoginLink: businessPortalLoginLink.value, className: styles.userIcon })),
                        (navigationMenu === null || navigationMenu === void 0 ? void 0 : navigationMenu.value) && (React.createElement(MenuLabelWithIcon, { isMenuOpened: isMenuOpened, menuToggle: menuToggle, menuToggler: menuToggler, className: styles.mainMenu })))))),
        React.createElement(MonsterNavigation, { isOpened: isMenuOpened, languagePicker: languagePicker }),
        React.createElement("div", { ref: searchRef },
            React.createElement(Search, { isOpened: isSearchOpened, searchPage: searchPage, homepageSearch: homepageSearch === null || homepageSearch === void 0 ? void 0 : homepageSearch.expandedValue }))));
}
