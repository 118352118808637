var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { brandColors } from "@basics/brand-colors";
import { rem, onBreakpoint, CONTAINER_OFFSET_WIDTHS, MARGIN_OF_CONTAINER, GUTTER_WIDTHS, } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { createUseStyles } from "react-jss";
export var useStyles = createUseStyles({
    element: __assign(__assign({ marginBottom: rem(spacings.m) }, onBreakpoint("sm", {
        display: "block",
        marginBottom: rem(spacings.l),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.xxl),
    })),
    isSingle: {},
    isThree: {},
    left: {},
    right: {},
    backgroundRowColumn: __assign({ display: "none", "&$left": {
            gridColumnStart: 1,
            "&:not($isThree) > div": __assign(__assign({ left: "calc(-".concat(CONTAINER_OFFSET_WIDTHS["small"], "px)") }, onBreakpoint("sm", {
                left: "calc(-".concat(CONTAINER_OFFSET_WIDTHS["big"], "px)"),
            })), onBreakpoint("md", {
                left: "calc(-1 * ".concat(MARGIN_OF_CONTAINER, " - ").concat(CONTAINER_OFFSET_WIDTHS["big"], "px)"),
            })),
        }, "&$right": {
            gridColumnStart: 7,
            "&:not($isThree) > div": __assign(__assign({ right: "calc(-".concat(CONTAINER_OFFSET_WIDTHS["small"], "px)") }, onBreakpoint("sm", {
                right: "calc(-".concat(CONTAINER_OFFSET_WIDTHS["big"], "px)"),
            })), onBreakpoint("md", {
                right: "calc(-1 * ".concat(MARGIN_OF_CONTAINER, " - ").concat(CONTAINER_OFFSET_WIDTHS["big"], "px)"),
            })),
        }, "& > div": {
            position: "absolute",
            height: "100%",
            width: "50vw",
        }, "&$isSingle > div": {
            width: "100%",
        }, "&$isThree > div": __assign({ display: "none" }, onBreakpoint("md", {
            display: "block",
            width: "calc(100% + ".concat(GUTTER_WIDTHS["big"] / 2, "px)"),
        })), "&$right&$isThree": {
            gridColumnStart: 9,
            "& > div": {
                left: -GUTTER_WIDTHS["big"] / 2,
            },
        }, "&$threeBackgroundItem&$isThree": {
            gridColumnStart: 5,
            gridColumnEnd: "span 4",
            "& > div": {
                width: "calc(100% + ".concat(GUTTER_WIDTHS["big"], "px)"),
                left: -GUTTER_WIDTHS["big"] / 2,
            },
        } }, onBreakpoint("sm", {
        gridRow: 1,
        display: "block",
    })),
    threeBackgroundItem: __assign({ display: "none" }, onBreakpoint("md", {
        display: "block",
    })),
    lightItem: {
        backgroundColor: brandColors.ultraLightGrey,
    },
    mobileDarkTabletDesktopLightItem: __assign({ backgroundColor: brandColors.lightGrey }, onBreakpoint("sm", {
        backgroundColor: brandColors.ultraLightGrey,
    })),
    darkVeryDarkItem: {
        backgroundColor: brandColors.mediumGrey,
    },
    darkItem: {
        backgroundColor: brandColors.lightGrey,
    },
    mobileLightTabletDesktopLDarkItem: __assign({ backgroundColor: brandColors.ultraLightGrey }, onBreakpoint("sm", {
        backgroundColor: brandColors.lightGrey,
    })),
    rightBackgroundThreeItem: {
        backgroundColor: brandColors.ultraLightGrey,
    },
});
