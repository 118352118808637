var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { animations } from "@basics/animations";
import { brandColors } from "@basics/brand-colors";
import { onBefore, onBreakpoint, rem } from "@basics/layout";
import { spacings } from "@basics/spacings";
import { customTypography, typography } from "@basics/typography";
import { FAIconStyles } from "@components/FAIcon/FAIcon";
export var ICON_SIZE_DESKTOP = 25;
export var ICON_SIZE_MOBILE = 20;
export var headerMixins = __assign({}, onBefore("sm", {
    transform: "none !important",
}));
export var headerTop = __assign({ backgroundColor: brandColors.lightGrey, display: "none" }, onBreakpoint("sm", {
    display: "block",
}));
export var headerTopContent = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& ul": {
        display: "flex",
        padding: 0,
        "& li": __assign(__assign({ listStyle: "none", margin: "0 ".concat(spacings.sam.divide(2)) }, onBreakpoint("md", {
            margin: "0 ".concat(spacings.sam),
        })), { "& a": __assign({}, customTypography(__assign(__assign(__assign({}, typography.textXSmall), { marginBottom: 0, color: brandColors.darkGrey, transition: "color 0.3s ease", "&:hover": {
                    color: brandColors.black,
                } }), animations.linkChevronEffect), {}, {
                marginBottom: 0,
            })) }),
    },
};
export var headerBottomContent = __assign(__assign({ display: "flex", alignItems: "center", position: "relative", gap: rem(spacings.sam) }, onBreakpoint("sm", {
    gap: rem(spacings.m),
})), { justifyContent: "flex-end" });
export var navLeft = {
    display: "flex",
    alignItems: "center",
    "& li:first-child": {
        marginLeft: 0,
    },
};
export var navRight = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
};
export var contactUsButtonWrapper = __assign({ opacity: "0", transition: "opacity 0.65s ease", pointerEvents: "none" }, onBefore("sm", {
    display: "none",
}));
export var contactUsButtonWrapperShow = {
    opacity: "1",
    pointerEvents: "auto",
};
export var userIcon = __assign(__assign({ cursor: "pointer" }, FAIconStyles({
    width: ICON_SIZE_MOBILE,
    height: ICON_SIZE_MOBILE,
})), onBreakpoint("sm", __assign({}, FAIconStyles({
    width: ICON_SIZE_DESKTOP,
    height: ICON_SIZE_DESKTOP,
    transition: "all .3s",
}))));
export var searchIcon = __assign(__assign({ cursor: "pointer", transition: "all .3s" }, FAIconStyles({
    width: ICON_SIZE_MOBILE,
    height: ICON_SIZE_MOBILE,
})), onBreakpoint("sm", __assign({}, FAIconStyles({
    width: ICON_SIZE_DESKTOP,
    height: ICON_SIZE_DESKTOP,
}))));
export var iconsWrapper = __assign(__assign({ display: "flex", gap: rem(spacings.sam) }, onBreakpoint("sm", {
    gap: "36px",
})), { alignItems: "center" });
export var closeIcon = __assign(__assign(__assign({ position: "relative", color: function (_a) {
        var isMenuCollapsed = _a.isMenuCollapsed;
        return isMenuCollapsed ? brandColors.black : brandColors.white;
    }, marginBottom: "-2px", cursor: "pointer", alignItems: "baseline" }, FAIconStyles({
    width: ICON_SIZE_MOBILE + 8,
    height: ICON_SIZE_MOBILE + 8,
})), onBreakpoint("sm", __assign({}, FAIconStyles({
    width: ICON_SIZE_DESKTOP,
    height: ICON_SIZE_DESKTOP,
})))), { stroke: brandColors.white, strokeWidth: "20px" });
